import { FC, forwardRef, useCallback } from 'react'
import {
  LocalizationProvider,
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers'
import omit from 'lodash/fp/omit'
import GridItem, { GridItemProps, omitGridProps } from './GridItem'
import { validate } from '../../../utils'
import { Controller, useFormContext } from 'react-hook-form'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'

const Comp = forwardRef((allProps: any, ref) => {
  const { field, fieldState, ...props } = allProps
  const showError = fieldState.invalid
  const fieldError = fieldState.error ? fieldState.error.message : ''
  const muiProps: DatePickerProps = {
    error: showError,
    helperText: showError ? fieldError : props.helperText,
    ...field,
    ...props,
    ref,
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDatePicker {...muiProps} />
    </LocalizationProvider>
  )
})

type DatePickerProps = {
  name?: string
  label?: string
  required?: boolean
  validators?: Parameters<typeof validate>[0]
} & GridItemProps &
  MuiDatePickerProps<any>

/**
 * Date picker component sized like our other form components
 * @param props {
 *   name: react-hook-form's name
 *   label?: label to show
 *   validators?: validation rules for react-hook-form
 *   required?: adds validation
 * }
 * @constructor
 */
const DatePicker: FC<DatePickerProps> = (props) => {
  const { setValue } = useFormContext()
  const { validators = {}, name } = props

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value

      if (typeof value === 'number' && isNaN(value)) {
        setValue(name!, '')
      } else {
        setValue(name!, value)
      }
    },
    [name, setValue],
  )

  if (props.required) {
    validators.presence = true
  }

  return (
    <GridItem {...props}>
      <Controller
        name={name!}
        rules={{
          validate: validate(validators),
        }}
        render={({ field: { ref, ...field }, fieldState }) => {
          return (
            <Comp
              inputRef={ref}
              field={field}
              fieldState={fieldState}
              fullWidth
              sx={{ width: '100%' }}
              margin="normal"
              autoComplete="off"
              onChange={(e: any) => {
                field.onChange(e)
                handleOnChange(e)
              }}
              {...omitGridProps(omit(['validators'], props))}
              data-test-id={props.name ? `datepicker-${props.name}` : undefined}
            />
          )
        }}
      />
    </GridItem>
  )
}

export default DatePicker
