import { EdgeClient } from 'common/generated/edgeClient'
import {
  KubernetesNode,
  KubernetesNodeFilter,
  KubernetesNodeResourceUsage,
  ListResult,
  UpdateKubernetesNodePayload,
} from 'common/api/v1/types'
import { KubernetesNodesRequestParams, singleSortQueryFromPaginatedRequestParams } from '../nm-types'

export interface IKubernetesApi {
  getKubernetesNodes(params: KubernetesNodesRequestParams): Promise<ListResult<KubernetesNode>>

  getKubernetesNode(regionId: string, name: KubernetesNode['name']): Promise<KubernetesNode>

  updateKubernetesNode(regionId: string, name: string, update: UpdateKubernetesNodePayload): Promise<KubernetesNode>

  getResourceUsage(): Promise<KubernetesNodeResourceUsage[]>
}

export class KubernetesApi implements IKubernetesApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  async getKubernetesNodes({
    filter: searchName,
    name,
    ...params
  }: KubernetesNodesRequestParams): Promise<ListResult<KubernetesNode>> {
    const filter: KubernetesNodeFilter = { searchName, name }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams: params })
    return this.edgeClient.listKubernetesNodes(query)
  }

  async getKubernetesNode(regionId: string, name: KubernetesNode['name']): Promise<KubernetesNode> {
    return this.edgeClient.getKubernetesNode(regionId, name)
  }

  async updateKubernetesNode(
    regionId: string,
    name: string,
    update: UpdateKubernetesNodePayload,
  ): Promise<KubernetesNode> {
    return this.edgeClient.updateKubernetesNode(regionId, name, update)
  }

  async getResourceUsage(): Promise<KubernetesNodeResourceUsage[]> {
    return this.edgeClient.listResourceUsages()
  }
}
