import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'

import { Appliance, GeoCoreNode, Role, User } from 'common/api/v1/types'
import { getApplianceOwnerId, isCoreNode } from '../../../utils'
import { Link } from '../../common/Link'

import { useRoutes } from '../../../store'

/**
 * Showing single appliance popup
 * @param name - appliance name
 * @param id - appliance id for the link
 * @constructor
 */
export const AppliancePopup = ({ name, id, linkAvailable }: { name: string; id: string; linkAvailable: boolean }) => {
  const routes = useRoutes()
  return (
    <Link to={routes.appliancesUpdate({ id })} underline="hover" available={linkAvailable}>
      <Typography component="div" variant="body2">
        {name}
      </Typography>
    </Link>
  )
}

const isGeoCoreNode = (appliance: Pick<Appliance, 'type'>): appliance is GeoCoreNode => isCoreNode(appliance.type)

/**
 * List of appliances popup
 * @param appliances
 * @constructor
 */
export const AppliancesPopup = ({
  appliances,
  user,
}: {
  appliances: Array<Pick<Appliance, 'name' | 'id' | 'type'> & { owner?: Appliance['owner'] }>
  user: User
}) => (
  <List dense disablePadding>
    {appliances.map((appliance) => {
      const { name, id } = appliance
      let hasAccessToNode = false

      if (isGeoCoreNode(appliance)) {
        hasAccessToNode = user.role === Role.super
      } else {
        const applianceOwner = getApplianceOwnerId(appliance)
        hasAccessToNode = user.role === Role.super || user.group === applianceOwner
      }

      return (
        <ListItem disableGutters key={`appliance-popup-${id}`}>
          <ListItemText>
            <AppliancePopup name={name} id={id} linkAvailable={hasAccessToNode} />
          </ListItemText>
        </ListItem>
      )
    })}
  </List>
)
