import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import merge from 'lodash/merge'
import * as uuid from 'uuid'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Theme } from '@mui/material/styles'

import { Group, GroupInit, Role } from 'common/api/v1/types'
import { AppDispatch, GlobalState, useRoutes } from '../../../store'
import { ButtonsPane, Form, Paper, SafeRouting, TextInput } from '../../common/Form'
import Pendable from '../../common/Pendable'
import { formTransform, useConfirmationDialog, useUser } from '../../../utils'
import { clearGroup, createGroup, getGroup, removeGroup, updateGroup } from '../../../redux/actions/groupActions'

import Lists from './Lists'
import Wrapper from '../../common/Wrapper'
import { generatePassword } from '../../../utils/password'

import RHF, { FormProps } from '../../common/Form/RHF'

const styles = {
  warning: {
    color: (theme: Theme) => theme.palette.error.main,
    fontWeight: 'bold',
  },
}

const getInitialState = (group?: Group): Group | GroupInit =>
  merge(
    {
      name: '',
      applianceSecret: '',
      adminUsername: '',
      adminPassword: '',
    },
    group,
  )

const isEditing = (values: Group | GroupInit): values is Group => 'id' in values && !!values.id

const GroupForm = ({ setValue, getValues, setError, watch, clearErrors, formState }: FormProps<Group | GroupInit>) => {
  const values = getValues()
  const routes = useRoutes()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const setConfirmation = useConfirmationDialog()
  const { formErrors, saving } = useSelector(
    ({ groupsReducer }: GlobalState) => ({
      formErrors: groupsReducer.formErrors,
      saving: groupsReducer.saving,
    }),
    shallowEqual,
  )
  const user = useUser()

  const setSecret = () => setValue('applianceSecret', uuid.v4())
  const [showPassword, setShowPassword] = useState({ show: false, id: '' })

  useEffect(() => {
    if (Array.isArray(formErrors)) {
      formErrors.forEach((item) => {
        setError(item.name as keyof Group, {
          type: 'manual',
          message: item.reason,
        })
      })
    }
  }, [formErrors])

  useEffect(() => {
    if (getValues('applianceSecret') === '') {
      setSecret()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  watch(['adminUsername', 'adminPassword', 'applianceSecret'])

  const onRefreshClick = isEditing(values)
    ? () =>
        setConfirmation(
          () => setSecret(),
          <>
            <Box sx={styles.warning}>Warning!</Box>
            <span>
              {' '}
              New appliances will need to use the new secret to register. Previously registered appliances can still use
              the secret they were originally registered with.
            </span>
          </>,
        )
    : () => setSecret()
  const deleteGroup = () =>
    isEditing(values) &&
    setConfirmation(
      () => void dispatch(removeGroup(values.id)),
      `Are you sure you want to delete group ${values.name}?`,
    )

  if (user.role !== Role.super && (user.role !== Role.admin || user.group !== (values as Group).id)) {
    navigate(-1)
    return null
  }

  const onGeneratePasswordClick = () => {
    const adminPassword = generatePassword(12, 12)
    setValue('adminPassword', adminPassword)
    setShowPassword({ show: true, id: adminPassword })
  }
  const onAdminAccountCollapseChange = (collapsed: boolean) => {
    if (collapsed) {
      setValue('adminPassword', '')
      setValue('adminUsername', '')
      clearErrors('adminPassword')
      clearErrors('adminUsername')
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <SafeRouting formState={formState} />
        <Form id="group-form" noValidate>
          <Paper
            title="Group metadata"
            actionsPane={[
              getValues('applianceSecret') && navigator.clipboard ? (
                {
                  props: { variant: 'outlined' },
                  title: 'Copy secret',
                  onClick: () => void navigator.clipboard.writeText(getValues('applianceSecret') || ''),
                }
              ) : (
                <></>
              ),
              { id: 'button-refresh-secret', title: 'Generate new secret', onClick: onRefreshClick },
            ]}
          >
            <TextInput name="name" label="Name" required autoFocus />
            <TextInput name="applianceSecret" label="Secret" required disabled />
          </Paper>

          {!isEditing(values) && (
            <Paper
              title="Create Group Admin"
              collapsed
              collapsible
              actionsPane={[
                { id: 'button-generate-password', title: 'Generate password', onClick: onGeneratePasswordClick },
              ]}
              onCollapseChange={onAdminAccountCollapseChange}
            >
              <TextInput
                name="adminUsername"
                label="Username"
                required={!!values.adminPassword && values.adminPassword.length > 0}
                autoComplete="off"
                type="text"
                validators={values.adminUsername ? { username: {} } : {}}
              />
              <TextInput
                name="adminPassword"
                label="Password"
                autoComplete="new-password"
                required={!!values.adminUsername && values.adminUsername.length > 0}
                type="password"
                showPassword={showPassword}
                validators={{
                  pwd: {},
                }}
              />
            </Paper>
          )}

          {isEditing(values) && <Lists groupId={values.id} />}
          <ButtonsPane
            main={{
              Cancel: {
                onClick: () => navigate(routes.groups()),
              },
              Save: { savingState: !!saving, primary: true, type: 'submit' },
            }}
            secondary={
              isEditing(values) && user.role === Role.super
                ? {
                    'Remove group': { onClick: deleteGroup },
                  }
                : undefined
            }
          />
        </Form>
      </Grid>
    </Grid>
  )
}

export const Edit = () => {
  const { id } = useParams()
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    id && dispatch(getGroup(id))
    return () => {
      dispatch(clearGroup())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const groupToEdit = useSelector(({ groupsReducer }: GlobalState) => groupsReducer.group, shallowEqual)
  const onSubmit = (group: Group | GroupInit) => {
    if (groupToEdit) dispatch(updateGroup(group as Group))
    else dispatch(createGroup(group as GroupInit))
  }

  return (
    <Wrapper name={['Groups', id ? groupToEdit?.name : 'New']}>
      <Grid container spacing={0}>
        <Pendable pending={!!id && !groupToEdit}>
          <RHF
            onSubmit={(values) => {
              onSubmit(formTransform(values))
            }}
            defaultValues={getInitialState(groupToEdit)}
            component={GroupForm}
          />
        </Pendable>
      </Grid>
    </Wrapper>
  )
}
