import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import capitalize from '@mui/utils/capitalize'
import min from 'lodash/min'

import {
  AnyRistServerOutputMetric,
  Appliance,
  CoaxPortMode,
  ComprimatoPortMode,
  GraphNodeType,
  Input,
  InputAdminStatus,
  InputPort,
  IpcPortMode,
  IpPortMode,
  LimitedAppliance,
  MatroxPortMode,
  MetricWindow,
  Output,
  OutputAdminStatus,
  OutputPort,
  PortMode,
  RistInputMetrics,
  RistMetricType,
  RistOutputMetrics,
  RistSimpleProfileOutputPort,
  Role,
  RtmpOutputPort,
  RtpInputMetrics,
  RtpInputPort,
  RtpOutputMetrics,
  RtpOutputPort,
  SrtCallerInputPort,
  SrtCallerOutputPort,
  SrtListenerInputPort,
  SrtListenerOutputPort,
  SrtMode,
  SrtRendezvousInputPort,
  SrtRendezvousOutputPort,
  StreamMetrics,
  UdpInputMetrics,
  UdpInputPort,
  UdpOutputMetrics,
  UdpOutputPort,
  User,
  VaInputPipe,
  VaObject,
  VaObjectInputType,
  VaOutputPipe,
  VideonPortMode,
  ZixiMode,
  ZixiPullInputPort,
  ZixiPushOutputPort,
} from 'common/api/v1/types'

import { GlobalState, useRoutes } from '../../../store'
import DataSet from '../../common/DataSet'
import { isGraphNodeApplianceType, packets, TsInfo } from './Statistics'
import { vaInputStatistics, VaOutputStatistics } from './VaStatistics'
import { InfoSection } from './InfoSection'
import {
  InputHealthIndicator,
  OutputHealthIndicator,
  ServiceOverviewApplianceHealthIndicator,
} from '../../common/Indicator'
import { ConnectionData, SelectedGraphItem } from '../Graph/ServiceOverviewGraph'
import { ExternalLink, Link } from '../../common/Link'
import { hasAccessToAppliance, inputType, outputType, pluralize, pluralizeWord } from '../../../utils'
import { applyUnit, getActiveChannelId, makeFormatter } from './utils'
import { isMatroxApplianceType, isVaApplianceType } from 'common/applianceTypeUtil'
import {
  formatBitrate,
  getFormattedTransportStreamContent,
  isComprimatoPortMode,
  isIpcPortMode,
  isMatroxPortMode,
  isMptsMetrics,
  isOutputMetricsWithPacketsLost,
  isRistInputMetrics,
  isRistOutputMetrics,
  isRistServerPortMode,
  isRistSimpleProfileInputMetrics,
  isRistSimpleProfileOutputMetrics,
  isRtmpInputMetric,
  isRtmpOutputMetric,
  isRtpInputMetrics,
  isRtpOutputMetrics,
  isSrtInputMetric,
  isSrtOutputMetric,
  isUdpInputMetrics,
  isUdpOutputMetrics,
  isUnixInputMetrics,
  isUnixOutputMetrics,
  isVideonPortMode,
  isZixiInputMetrics,
  isZixiOutputMetric,
} from 'common/api/v1/helpers'
import { TunnelLabels } from 'common/api/v1/internal'
import Tr101290Page from './TR101290'
import { ServiceOverviewState } from '../../../redux/reducers/serviceOverviewReducers'
import { ageSeconds } from 'common/util'
import { distinct } from 'common/utils'
import { Routes } from '../../../utils/routes'
import { ApplianceProxyButton } from '../../common/ApplianceProxyButton'
import { TransportStream } from 'common/tr101Types'
import { isRtpOverSrt } from 'common/srt'

interface ClientConnectionDetails {
  'Connection type': 'Client'
  'Connecting from'?: string
  'Connecting to'?: string
}
interface ServerConnectionDetails {
  'Connection type': 'Server'
  'Listening on'?: string
  'Multicast group'?: string
  'Multicast groups'?: string
}
type ClientServerConnectionDetails = Omit<ClientConnectionDetails, 'Connection type'> &
  Omit<ServerConnectionDetails, 'Connection type'> & { 'Connection type': 'Client and server' }

type ConnectionDetails =
  | ClientConnectionDetails
  | ServerConnectionDetails
  | ClientServerConnectionDetails
  | Record<string, never>

interface PortMetadata {
  portAppliance?: LimitedAppliance
  streamId?: number
  ristMetrics: StreamMetrics[]
  connectionDetails: ConnectionDetails
}
interface OutputPortMetadata extends PortMetadata {
  logicalPort: OutputPort
}
interface InputPortMetadata extends PortMetadata {
  logicalPort?: InputPort // Derived inputs have no logical port
  isDerivedInput: boolean
}

function isUdpInputPortWithRtpFormat(logicalPort: InputPort): logicalPort is RtpInputPort {
  // having mode rtp and failoverPriority means it will be added to the ristserver as a udp-input with format="rtp" used for source failover
  return logicalPort.mode === IpPortMode.rtp && logicalPort.failoverPriority !== undefined
}

function isUdpOutputPortWithRtpFormat(logicalPort: OutputPort): boolean {
  return isRtpOverSrt(logicalPort)
}

function isRtpInputPort(inputPort: InputPort): inputPort is RtpInputPort {
  return inputPort.mode === IpPortMode.rtp
}

const portModesWithUdpMetrics: (InputPort | OutputPort)['mode'][] = [
  IpPortMode.udp,
  MatroxPortMode.matroxSdi, // Matrox output is (currently) over UDP
  VideonPortMode.videonAuto,
  VideonPortMode.videonHdmi,
  VideonPortMode.videonSdi,
]

const inputPortHasRtpMetrics = (lp: InputPort) => {
  if (isRtpOverSrt(lp)) return true
  if (lp.mode === IpPortMode.rtp) return true
  if (lp.mode === IpPortMode.zixi) return true
  if (lp.mode === MatroxPortMode.matroxSdi) return true
  if (lp.mode === ComprimatoPortMode.comprimatoNdi) return true
  if (lp.mode === ComprimatoPortMode.comprimatoSdi) return true
  return false
}
const outputPortHasRtpMetrics = (lp: OutputPort) => {
  if (isUdpOutputPortWithRtpFormat(lp)) return true
  if (lp.mode === IpPortMode.rtp) return true
  if (lp.mode === IpPortMode.zixi) return true
  if (lp.mode === ComprimatoPortMode.comprimatoNdi) return true
  if (lp.mode === ComprimatoPortMode.comprimatoSdi) return true
  return false
}

function findActiveOutputInGroupByStreamId<T extends AnyRistServerOutputMetric>(egressMetrics: T[], streamId?: number) {
  let metrics = egressMetrics.find((m) => m.streamId === streamId)
  if (metrics && egressMetrics) {
    const channelGroupMirrors = egressMetrics.filter(
      (m) => m.applianceId === metrics?.applianceId && m.inputId === metrics.inputId && m.type === metrics.type,
    )
    for (const m of channelGroupMirrors) {
      if (m.sendPacketrate > metrics?.sendBitrate) {
        metrics = m
      }
    }
  }
  return metrics
}

function findUdpOutputMetrics(
  { logicalPort, portAppliance, ristMetrics, streamId }: OutputPortMetadata,
  output: Output,
) {
  const isVaOutput = portAppliance && isVaApplianceType(portAppliance.type)
  if (isVaOutput) {
    // VAs have their own UDP outputs (udpSrc) - the handover to them is made over RTP (an UDP output with packetFormat=RTP is added to the ristserver)
    // - so a "rtpOutputInfo handover" section should be displayed instead
    return null
  }

  const isSrtBondedOutput =
    logicalPort?.mode === IpPortMode.srt &&
    logicalPort?.srtMode !== SrtMode.rendezvous &&
    typeof logicalPort?.failoverPriority === 'number'

  const ristServerOutputStreamId = isSrtBondedOutput ? min(output.ports.map((p) => p.internalStreamId)) : streamId

  const egressMetrics = ristMetrics.filter(isUdpOutputMetrics).filter((m) => m.isEgress)
  if (egressMetrics.length === 0) {
    return null
  }
  const metrics = findActiveOutputInGroupByStreamId(egressMetrics, ristServerOutputStreamId)
  if (!metrics && !portModesWithUdpMetrics.includes(logicalPort.mode)) {
    // This is likely to be an upgraded appliance that should not have udp info
    // It will however potentially miss issues where unupdated appliances are missing udp metrics for some reason
    return null
  }
  return metrics
}

function udpOutputInfo(
  { logicalPort, portAppliance, ristMetrics, connectionDetails, streamId }: OutputPortMetadata,
  output: Output,
) {
  const metrics = findUdpOutputMetrics({ logicalPort, portAppliance, ristMetrics, connectionDetails, streamId }, output)
  if (!metrics) {
    return null
  }
  return udpOutputInfoTable(metrics, logicalPort?.mode === IpPortMode.udp ? connectionDetails : undefined)
}

function udpOutputInfoTable(metrics: UdpOutputMetrics, connectionDetails?: ConnectionDetails) {
  const metric = makeFormatter(metrics)
  return {
    'Send bitrate': metric((s) => formatBitrate(s.sendBitrate)),
    'Packet rate': metric((s) => Math.round(s.sendPacketrate)),
    'Packets lost': metric((s) => s.packetsLost),
    'Packets dropped': metric((s) => s.packetsDropped),
    'Playout margin': metric((s) => applyUnit(s.playoutMarginMs, 'ms')),
    'Internal Stream ID': metric((s) => s.streamId),
    'Internal Channel ID': metric((s) => s.channelId),
    ...(connectionDetails ?? {}),
  }
}

function unixOutputInfo({ logicalPort, ristMetrics, connectionDetails, streamId, portAppliance }: OutputPortMetadata) {
  const portApplianceType = portAppliance?.type
  const portModesWithUnixOutput: OutputPort['mode'][] = [IpcPortMode.unix]
  const applianceUsesUnixHandover =
    portApplianceType && !isVaApplianceType(portApplianceType) && !isMatroxApplianceType(portApplianceType)
  if (!applianceUsesUnixHandover || !portModesWithUnixOutput.includes(logicalPort.mode)) {
    return null
  }
  const matchingEgressMetrics = ristMetrics.filter((m) => m.isEgress).filter((m) => m.streamId === streamId)

  // TODO: remove this when all appliances are upgraded with unix socket support
  // (it ensures that we will not show double handover information for unupgraded appliances)
  const metrics = matchingEgressMetrics?.find(isUnixOutputMetrics)
  if (matchingEgressMetrics?.[0] && !metrics) {
    return null
  }

  const metric = makeFormatter(metrics)
  return {
    'Send bitrate': metric((s) => formatBitrate(s.sendBitrate)),
    'Packet rate': metric((s) => Math.round(s.sendPacketrate)),
    'Playout margin': metric((s) => applyUnit(s.playoutMarginMs, 'ms')),
    'Internal Stream ID': metric((s) => s.streamId),
    'Internal Channel ID': metric((s) => s.channelId),
    ...(logicalPort.mode === IpcPortMode.unix ? connectionDetails : {}),
  }
}

function rtmpInputInfo({ logicalPort, streamId, ristMetrics, connectionDetails }: InputPortMetadata) {
  if (logicalPort && logicalPort.mode !== IpPortMode.rtmp) {
    return null
  }
  const metrics = ristMetrics.filter(isRtmpInputMetric).find((metric) => metric.streamId === streamId)
  return {
    'Receive bitrate': formatBitrate(metrics ? metrics.receiveBitrateKbps * 1000 : null),
    ...connectionDetails,
  }
}

function rtmpOutputInfo({ logicalPort, streamId, ristMetrics, connectionDetails }: OutputPortMetadata) {
  if (logicalPort.mode !== IpPortMode.rtmp) {
    return null
  }
  const metrics = ristMetrics.filter(isRtmpOutputMetric).find((metric) => metric.streamId === streamId)
  const metric = makeFormatter(metrics)
  return {
    Restarts: metric((s) => s.restartCount),
    'Send bitrate': metric((s) => formatBitrate(s.sendBitrateKbps * 1000)),
    ...connectionDetails,
  }
}

function unixInputInfo({ logicalPort, streamId, ristMetrics, connectionDetails, portAppliance }: InputPortMetadata) {
  const portModesWithUnixInput: Exclude<Input['ports'], undefined>[number]['mode'][] = [IpcPortMode.unix]
  const isVa = portAppliance && isVaApplianceType(portAppliance.type)
  if ((logicalPort && !portModesWithUnixInput.includes(logicalPort.mode)) || isVa) {
    return null
  }
  const unixReceiveMetrics = ristMetrics.filter(isUnixInputMetrics).find((metric) => metric.streamId === streamId)
  if (!unixReceiveMetrics) {
    return null
  }
  // TODO: remove this when all appliances support unix sockets
  const udpReceiveMetrics = ristMetrics.filter(isUdpInputMetrics).find((metric) => metric.streamId === streamId)
  if (udpReceiveMetrics) {
    return null
  }

  const receiveMetric = makeFormatter(unixReceiveMetrics)
  return {
    'Receive bitrate': receiveMetric((s) => formatBitrate(s.receiveBitrate)),
    'Packet rate': receiveMetric((s) => Math.round(s.receivePacketrate)),
    'Packets discarded during standby': receiveMetric((s) => s.packetsWhileInactive),
    'Internal Stream ID': receiveMetric((s) => s?.streamId),
    'Internal Channel ID': receiveMetric((s) => s.channelId),
    Status: receiveMetric((s) => s?.status),
    ...connectionDetails,
  }
}

function udpInputInfo({ logicalPort, streamId, ristMetrics, connectionDetails }: InputPortMetadata) {
  const isSrtBondedInput =
    logicalPort?.mode === IpPortMode.srt &&
    logicalPort?.srtMode !== SrtMode.rendezvous &&
    typeof logicalPort?.failoverPriority === 'number'
  const isUdpInputWithRtpFormat = logicalPort && isUdpInputPortWithRtpFormat(logicalPort)
  const receiveMetrics = isUdpInputWithRtpFormat
    ? undefined
    : ristMetrics.filter(isUdpInputMetrics).find((metric) => metric.streamId === streamId || isSrtBondedInput)

  if (logicalPort?.mode === MatroxPortMode.matroxSdi) {
    // Matrox input handover uses RTP
    return null
  }

  if (logicalPort && !portModesWithUdpMetrics.includes(logicalPort.mode) && !receiveMetrics) {
    return null
  }

  const receiveMetric = makeFormatter(receiveMetrics)
  return {
    'Receive bitrate': receiveMetric((s) => formatBitrate(s.receiveBitrate)),
    'Packet rate': receiveMetric((s) => Math.round(s.receivePacketrate)),
    'Malformed packets discarded': receiveMetric((s) => s.packetsDiscarded),
    'Packets discarded during standby': receiveMetric((s) => s.packetsWhileInactive),
    'Internal Stream ID': receiveMetric((s) => s?.streamId),
    'Internal Channel ID': receiveMetric((s) => s.channelId),
    Status: receiveMetric((s) => s?.status),
    ...(logicalPort && logicalPort.mode === IpPortMode.udp ? connectionDetails : {}),
  }
}

function rtpInputInfo({ logicalPort, streamId, ristMetrics, connectionDetails, portAppliance }: InputPortMetadata) {
  const isVaApplianceInput = !!portAppliance && isVaApplianceType(portAppliance.type)
  if (!isVaApplianceInput && logicalPort && !inputPortHasRtpMetrics(logicalPort)) {
    return null
  }

  const isRtpInputMetricsOrUdpInputMetrics = (m: StreamMetrics): m is RtpInputMetrics | UdpInputMetrics =>
    isRtpInputMetrics(m) || (isUdpInputWithFormatRtp && isUdpInputMetrics(m))
  const isUdpInputWithFormatRtp = !!logicalPort && isUdpInputPortWithRtpFormat(logicalPort)
  const receiveMetrics = ristMetrics
    .filter(isRtpInputMetricsOrUdpInputMetrics)
    .find((metric) => metric.streamId === streamId)

  if (isVaApplianceInput && !receiveMetrics) {
    // TODO: Remove this when all VA inputs have likely moved
    // over to RTP for handover
    return null
  }

  const useFec = logicalPort && isRtpInputPort(logicalPort) && logicalPort.fec

  const receiveMetric = makeFormatter(receiveMetrics)
  return {
    'Receive bitrate': receiveMetric((s) => formatBitrate(s.receiveBitrate)),
    'Packet rate': receiveMetric((s) => Math.round(s.receivePacketrate)),
    'Packets discarded': receiveMetric((s) => s.packetsDiscarded),
    ...(receiveMetrics && isRtpInputMetrics(receiveMetrics)
      ? {
          SSRC: typeof receiveMetrics.ssrc === 'number' ? receiveMetrics.ssrc.toString() : 'N/A',
          ...(useFec
            ? {
                'FEC packet rate': receiveMetrics.fecReceivePacketrate
                  ? Math.round(receiveMetrics.fecReceivePacketrate).toString()
                  : 'N/A',
                'Packets recovered last 10s':
                  typeof receiveMetrics.packetsRecovered === 'number'
                    ? receiveMetrics.packetsRecovered.toString()
                    : 'N/A',
                'Unrecovered packet rate':
                  typeof receiveMetrics.unrecoveredPacketrate === 'number'
                    ? receiveMetrics.unrecoveredPacketrate.toFixed(1).toString()
                    : 'N/A',
              }
            : {}),
          'Packet loss':
            receiveMetrics.receivePacketrate > 0 && typeof receiveMetrics.lostPacketrate === 'number'
              ? (
                  (100 * receiveMetrics.lostPacketrate) /
                  (receiveMetrics.lostPacketrate + receiveMetrics.receivePacketrate)
                ).toFixed(1) + '%'
              : 'N/A',
        }
      : {}),
    'Internal Stream ID': receiveMetric((s) => s.streamId),
    'Internal Channel ID': receiveMetric((s) => s.channelId),
    ...(isVaApplianceInput ? {} : connectionDetails),
  }
}

function derivedInputInfo({ streamId, ristMetrics }: InputPortMetadata, deriveFrom: Input['deriveFrom']) {
  if (!deriveFrom || !deriveFrom.ingestTransform || deriveFrom.ingestTransform.type !== 'mpts-demux') {
    return null
  }
  const receiveMetrics = ristMetrics.filter(isMptsMetrics).find((metric) => metric.streamId === streamId)
  const receiveMetric = makeFormatter(receiveMetrics)
  return {
    'Udp packets': receiveMetric((s) => s.udpPackets),
    'Udp packet rate': receiveMetric((s) => Math.round(s.udpPacketsRate)),
    'Ts packets': receiveMetric((s) => s.tsPackets),
    'Ts packet rate': receiveMetric((s) => Math.round(s.tsPacketsRate)),
  }
}

function rtpOutputInfo({ logicalPort, ristMetrics, connectionDetails, streamId, portAppliance }: OutputPortMetadata) {
  const portApplianceType = portAppliance?.type
  const isVaApplianceOutput = !!portApplianceType && isVaApplianceType(portApplianceType)
  if (!isVaApplianceOutput && !outputPortHasRtpMetrics(logicalPort)) {
    return null
  }

  const isUdpOrRtpMetrics = (m: StreamMetrics): m is UdpOutputMetrics | RtpOutputMetrics => {
    return isRtpOutputMetrics(m) || isUdpOutputMetrics(m)
  }
  const egressMetrics = ristMetrics.filter(isUdpOrRtpMetrics).filter((m) => m.isEgress)
  const sendMetrics = egressMetrics ? findActiveOutputInGroupByStreamId(egressMetrics, streamId) : undefined
  if (isVaApplianceOutput && !sendMetrics) {
    // TODO: remove this when all VA outputs
    // have moved to RTP for handover
    return null
  }

  const sendMetric = makeFormatter(sendMetrics)
  const bufferedOutputMetrics = makeFormatter(sendMetrics as UdpOutputMetrics)
  const bufferedRtpOutputDetails =
    sendMetrics && isUdpOutputMetrics(sendMetrics)
      ? {
          'Packets dropped': bufferedOutputMetrics((s) => s.packetsDropped),
          'Playout margin': bufferedOutputMetrics((s) => applyUnit(s.playoutMarginMs, 'ms')),
        }
      : {}

  return {
    'Send bitrate': sendMetric((s) => formatBitrate(s.sendBitrate)),
    'Packet rate': sendMetric((s) => Math.round(s.sendPacketrate)),
    'Packets lost': sendMetric((s) => s.packetsLost),
    ...bufferedRtpOutputDetails,
    'Internal Stream ID': sendMetric((s) => s.streamId),
    'Internal Channel ID': sendMetric((s) => s.channelId),
    ...connectionDetails,
  }
}

function ristInputInfo({ logicalPort, streamId, ristMetrics, connectionDetails }: InputPortMetadata) {
  if (logicalPort && logicalPort.mode !== IpPortMode.rist) {
    return null
  }
  const receiveMetrics = ristMetrics
    .filter(isRistSimpleProfileInputMetrics)
    .find((metric) => metric.streamId === streamId)
  const receiveMetric = makeFormatter(receiveMetrics)

  return {
    'Receive bitrate': receiveMetric((s) => formatBitrate(s.receiveBitrate)),
    'Packet rate': receiveMetric((s) => Math.round(s.receivePacketrate)),
    'Packet send errors': receiveMetric((s) => s.packetSendErrors),
    'Packets discarded': receiveMetric((s) => s.packetsDiscarded),
    'Retransmission bitrate': receiveMetric((s) => formatBitrate(s.retransmitReceiveBitrate)),
    'Retransmission packet rate': receiveMetric((s) => Math.round(s.retransmitReceivePacketrate)),
    Roundtrip: receiveMetric((s) => applyUnit(s.roundtripMs, 'ms')),
    'Propagation delay': receiveMetric((s) => applyUnit(s.propagationDelayMs, 'ms')),
    'Packets lost': receiveMetric((s) => s.packetsLost),
    'Longest burst loss': receiveMetric((s) => s.longestBurstLoss),
    'RTP packets received': receiveMetric((s) => s.rtpPacketsReceived),
    'Malformed RTCP Packets Received': receiveMetric((s) => s.malformedRtcpPacketsReceived),
    'Unsupported RTCP Packets Received': receiveMetric((s) => s.unsupportedRtcpPacketsReceived),
    'Internal Stream ID': receiveMetric((s) => s.streamId),
    'Internal Channel ID': receiveMetric((s) => s.channelId),
    ...connectionDetails,
  }
}

function ristOutputInfo({ logicalPort, streamId, ristMetrics, connectionDetails }: OutputPortMetadata) {
  if (logicalPort.mode !== IpPortMode.rist) {
    return null
  }
  const receiveMetrics = ristMetrics
    .filter(isRistSimpleProfileOutputMetrics)
    .find((metric) => metric.streamId === streamId)

  const sendMetric = makeFormatter(receiveMetrics)
  return {
    'Send bitrate': sendMetric((s) => formatBitrate(s.sendBitrate)),
    'Packet rate': sendMetric((s) => Math.round(s.sendPacketrate)),
    'Packet send errors': sendMetric((s) => s.packetSendErrors),
    'Retransmission bitrate': formatBitrate(sendMetric((s) => s.retransmitSendBitrate)),
    'Retransmission packet rate': sendMetric((s) => Math.round(s.retransmitSendPacketrate)),
    'RTP packets sent': sendMetric((s) => s.rtpPacketsSent),
    'Reported packet loss': sendMetric((s) => applyUnit(s.reportedPacketLossPercent, '%')),
    Roundtrip: sendMetric((s) => applyUnit(s.roundtripMs, 'ms')),
    'RTCP receive packet rate': sendMetric((s) => Math.round(s.rtcpReceivePacketrate)),
    'Malformed RTCP Packets Received': sendMetric((s) => s.malformedRtcpPacketsReceived),
    'Unsupported RTCP Packets Received': sendMetric((s) => s.unsupportedRtcpPacketsReceived),
    'Internal Stream ID': sendMetric((s) => s.streamId),
    'Internal Channel ID': sendMetric((s) => s.channelId),
    ...connectionDetails,
  }
}

function zixiInputInfo({ logicalPort, streamId, ristMetrics, connectionDetails }: InputPortMetadata) {
  if (logicalPort && logicalPort.mode !== IpPortMode.zixi) {
    return null
  }
  const zixiInputMetrics = ristMetrics.filter(isZixiInputMetrics).find((metric) => metric.streamId === streamId)
  const receiveMetric = makeFormatter(zixiInputMetrics)
  return {
    'Zixi bitrate': receiveMetric((m) => formatBitrate(m.bitrate)),
    'Zixi connection': receiveMetric((m) => m.connectionStatus),
    ...connectionDetails,
  }
}

function zixiOutputInfo({ logicalPort, ristMetrics, streamId, connectionDetails }: OutputPortMetadata) {
  if (logicalPort.mode !== IpPortMode.zixi) {
    return null
  }
  const receiveMetrics = ristMetrics.filter(isZixiOutputMetric).find((metric) => metric.streamId === streamId)
  const receiveMetric = makeFormatter(receiveMetrics)
  return {
    'Zixi bitrate': receiveMetric((m) => m.sinkStats.outBitrate),
    'Zixi connection': receiveMetric((m) => m.connectionStatus),
    'Zixi rtt': receiveMetric((m) => m.sinkStats.rtt),
    'Zixi jitter': receiveMetric((m) => m.sinkStats.jitter),
    'Zixi packets': receiveMetric((m) => m.sinkStats.totalPackets),
    'Zixi packet rate': receiveMetric((m) => m.sinkStats.packetRate),
    'Zixi dropped': receiveMetric((m) => m.sinkStats.droppedPackets),
    'Zixi recovered': receiveMetric((m) => m.sinkStats.recoveredPackets),
    'Zixi not recovered': receiveMetric((m) => m.sinkStats.notRecoveredPackets),
    'Zixi failed sends': receiveMetric((m) => m.sinkStats.failedSends),
    'Zixi FEC packets': receiveMetric((m) => m.sinkStats.fecPackets),
    'Zixi ARQ requests': receiveMetric((m) => m.sinkStats.arqRequests),
    'Zixi ARQ recovered': receiveMetric((m) => m.sinkStats.arqRecovered),
    'Zixi ARQ duplicates': receiveMetric((m) => m.sinkStats.arqDuplicates),
    'Zixi overflows': receiveMetric((m) => m.sinkStats.overflows),
    ...connectionDetails,
  }
}

function srtInputInfo({ logicalPort, streamId, ristMetrics, connectionDetails }: InputPortMetadata) {
  if (logicalPort && logicalPort.mode !== IpPortMode.srt) {
    return null
  }
  const srtInputMetrics = ristMetrics.filter(isSrtInputMetric).find((metric) => metric.streamId === streamId)
  const receiveMetric = makeFormatter(srtInputMetrics)
  return {
    'SRT connection status': receiveMetric((m) => m.connectionStatus),
    'SRT bitrate': receiveMetric((m) => formatBitrate(m.bitrate)),
    'SRT rtt': receiveMetric((m) => applyUnit(m.rtt, 'ms')),
    'SRT packets lost': receiveMetric((m) => m.packetsLost),
    'SRT packets dropped': receiveMetric((m) => m.packetsDropped),
    'SRT packets retransmitted': receiveMetric((m) => m.packetsRetransmitted),
    // TODO: re-add this stat when it is available
    // 'SRT receive buffer, acknowledged': receiveMetric((m) => applyUnit(m.msRecvBuffer, 'ms')),
    'Internal Stream ID': receiveMetric((s) => s.streamId),
    'Remote address': receiveMetric((m) => m.remoteAddress),
    ...connectionDetails,
  }
}

function srtOutputInfo({ logicalPort, streamId, ristMetrics, connectionDetails }: OutputPortMetadata) {
  if (logicalPort.mode !== IpPortMode.srt) {
    return null
  }
  const srtOutputMetrics = ristMetrics.filter(isSrtOutputMetric).find((metric) => metric.streamId === streamId)
  const receiveMetric = makeFormatter(srtOutputMetrics)
  const srtInfo: { [key: string]: number | string } = {
    'SRT connection status': receiveMetric((m) => m.connectionStatus),
    'SRT bitrate': receiveMetric((m) => formatBitrate(m.bitrate)),
    'SRT rtt': receiveMetric((m) => applyUnit(m.rtt, 'ms')),
    'SRT packets dropped': receiveMetric((m) => m.packetsDropped),
    'SRT packets retransmitted': receiveMetric((m) => m.packetsRetransmitted),
    'SRT send buffer, unacknowledged': receiveMetric((m) => applyUnit(m.msSendBuffer, 'ms')),
    'Internal Stream ID': receiveMetric((s) => s.streamId),
    ...connectionDetails,
  }
  if (srtOutputMetrics?.remoteAddress) {
    srtInfo['Remote address'] = receiveMetric((m) => m.remoteAddress)
  }
  return srtInfo
}

function getInputConnectionDetails(
  input: Input,
  logicalPortId?: string,
): ClientConnectionDetails | ServerConnectionDetails | ClientServerConnectionDetails | undefined {
  const inputPorts = input.ports
  if (!inputPorts?.length) {
    return undefined
  }

  const port = logicalPortId ? inputPorts.find((port) => port.id === logicalPortId) || inputPorts[0] : inputPorts[0]
  const ports = logicalPortId ? [port] : inputPorts
  switch (port.mode) {
    case IpPortMode.rtmp: // fallthrough
    case IpPortMode.rist: // fallthrough
    case IpPortMode.generator: // fallthrough
      return makeServerConnectionDetails(
        (ports as (typeof port)[]).map((port) => ({
          listenAddress: port.address,
          listenPort: port.port,
        })),
      )

    case IpPortMode.udp:
      return makeServerConnectionDetails(
        (ports as UdpInputPort[]).map((port) => ({
          listenAddress: port.address,
          listenPort: port.port,
          multicastGroupAddress: port.multicastAddress,
          multicastSource: port.multicastSource,
        })),
      )

    case IpPortMode.rtp:
      return makeServerConnectionDetails(
        (ports as RtpInputPort[]).map((port) => ({
          listenAddress: port.address,
          listenPort: port.port,
          multicastGroupAddress: port.multicastAddress,
          multicastSource: port.multicastSource,
        })),
      )

    case IpPortMode.srt: {
      switch (port.srtMode) {
        case SrtMode.caller:
          return makeClientConnectionDetails(
            (ports as SrtCallerInputPort[]).map((port) => ({
              fromPort: port.localPort,
              toAddress: port.remoteIp,
              toPort: port.remotePort,
            })),
          )

        case SrtMode.listener:
          return makeServerConnectionDetails(
            (ports as SrtListenerInputPort[]).map((port) => ({
              listenAddress: port.localIp,
              listenPort: port.localPort,
            })),
          )

        case SrtMode.rendezvous: {
          return {
            ...makeClientConnectionDetails(
              (ports as SrtRendezvousInputPort[]).map((port) => ({
                fromAddress: port.localIp,
                fromPort: port.remotePort,
                toAddress: port.remoteIp,
                toPort: port.remotePort,
              })),
            ),
            ...makeServerConnectionDetails(
              (ports as SrtRendezvousInputPort[]).map((port) => ({
                listenAddress: port.localIp,
                listenPort: port.remotePort,
              })),
            ),
            'Connection type': 'Client and server',
          }
        }
      }
    }

    case IpPortMode.zixi:
      switch (port.zixiMode) {
        case ZixiMode.pull:
          return makeClientConnectionDetails(
            (ports as ZixiPullInputPort[]).flatMap((port) =>
              [port.remotePrimaryIp, port.remoteSecondaryIp].filter(Boolean).map((toAddress) => ({
                fromAddress: port.localIp,
                toAddress,
                toPort: port.pullPort,
              })),
            ),
          )

        case ZixiMode.push:
          // Note: zixi push inputs can only be set up on VA:s. It is not possible
          // to specify 'listenAddress' or 'listenPort' per input - this information
          // is configured on the associated VA and must be retrieved via the OAM api.
          return makeServerConnectionDetails([])
      }

    case CoaxPortMode.asi: // fallthrough
    case CoaxPortMode.sdi:
      return undefined
  }
}

function getOutputConnectionDetails(
  output: Output,
  logicalPortId?: string,
): ClientConnectionDetails | ServerConnectionDetails | ClientServerConnectionDetails | undefined {
  const outputPorts = output.ports
  if (!outputPorts?.length) return undefined

  const port = logicalPortId ? outputPorts.find((port) => port.id === logicalPortId) : outputPorts[0]
  const ports = logicalPortId ? [port] : outputPorts
  if (!port) {
    return undefined
  }
  switch (port.mode) {
    case IpPortMode.udp:
      return makeClientConnectionDetails(
        (ports as UdpOutputPort[]).map((port) => ({
          fromAddress: port.sourceAddress,
          fromPort: port.localPort,
          toAddress: port.address,
          toPort: port.port,
        })),
      )

    case IpPortMode.rist:
      return makeClientConnectionDetails(
        (ports as RistSimpleProfileOutputPort[]).map((port) => ({
          fromAddress: port.sourceAddress,
          fromPort: port.localPort,
          toAddress: port.address,
          toPort: port.port,
        })),
      )

    case IpPortMode.rtp:
      return makeClientConnectionDetails(
        (ports as RtpOutputPort[]).map((port) => ({
          fromAddress: port.sourceAddress,
          fromPort: port.localPort,
          toAddress: port.address,
          toPort: port.port,
        })),
      )

    case IpPortMode.rtmp:
      return makeClientConnectionDetails(
        (ports as RtmpOutputPort[]).map((port) => ({ toAddress: port.rtmpDestinationAddress })),
      )

    case IpPortMode.srt:
      switch (port.srtMode) {
        case SrtMode.caller:
          return makeClientConnectionDetails(
            (ports as SrtCallerOutputPort[]).map((port) => ({
              fromPort: port.localPort,
              toAddress: port.remoteIp,
              toPort: port.remotePort,
            })),
          )

        case SrtMode.listener:
          return makeServerConnectionDetails(
            (ports as SrtListenerOutputPort[]).map((port) => ({
              listenAddress: port.localIp,
              listenPort: port.localPort,
            })),
          )

        case SrtMode.rendezvous: {
          return {
            ...makeClientConnectionDetails(
              (ports as SrtRendezvousOutputPort[]).map((port) => ({
                fromAddress: port.localIp,
                fromPort: port.remotePort,
                toAddress: port.remoteIp,
                toPort: port.remotePort,
              })),
            ),
            ...makeServerConnectionDetails(
              (ports as SrtRendezvousOutputPort[]).map((port) => ({
                listenAddress: port.localIp,
                listenPort: port.remotePort,
              })),
            ),
            'Connection type': 'Client and server',
          }
        }
      }

    case IpPortMode.zixi:
      switch (port.zixiMode) {
        case ZixiMode.pull:
          // Note: zixi pull outputs can only be set up on VA:s. It is not possible
          // to specify 'listenAddress' or 'listenPort' per input - this information
          // is configured on the associated VA and must be retrieved via the OAM api.
          return makeServerConnectionDetails([])

        case ZixiMode.push:
          return makeClientConnectionDetails(
            (ports as ZixiPushOutputPort[]).flatMap((port) =>
              [port.linkSet1, port.linkSet2]
                .flat()
                .filter(Boolean)
                .map((zixiLink) => ({
                  fromAddress: zixiLink!.localIp,
                  toAddress: zixiLink!.remoteIp,
                  toPort: zixiLink!.remotePort,
                })),
            ),
          )
      }

    case CoaxPortMode.asi: // fallthrough
    case CoaxPortMode.sdi:
      return undefined
  }
}

function makeClientConnectionDetails(
  entries: Array<{ fromAddress?: string; fromPort?: number; toAddress?: string; toPort?: number }>,
): ClientConnectionDetails {
  const fromEntries = Array.from(
    new Set(
      entries
        .map(({ fromAddress, fromPort }) => {
          if (fromAddress && fromPort) return `${fromAddress}:${fromPort}`
          else if (fromAddress) return fromAddress
          else if (fromPort) return `port ${fromPort}`
          return undefined
        })
        .filter(Boolean),
    ),
  )

  const toEntries = Array.from(
    new Set(
      entries
        .map(({ toAddress, toPort }) => {
          if (toAddress && toPort) return `${toAddress}:${toPort}`
          else if (toAddress) return toAddress
          else if (toPort) return `port ${toPort}`
          return undefined
        })
        .filter(Boolean),
    ),
  )

  return {
    'Connection type': 'Client',
    ...(fromEntries.length ? { 'Connecting from': fromEntries.join(', ') } : undefined),
    ...(toEntries.length ? { 'Connecting to': toEntries.join(', ') } : undefined),
  }
}

function makeServerConnectionDetails(
  entries: Array<{
    listenAddress?: string
    listenPort?: number
    multicastGroupAddress?: string
    multicastSource?: string
  }>,
): ServerConnectionDetails {
  const listenEntries = Array.from(
    new Set(
      entries
        .map(({ listenAddress, listenPort }) => {
          if (listenAddress && listenPort) return `${listenAddress}:${listenPort}`
          else if (listenAddress) return listenAddress
          else if (listenPort) return `port ${listenPort}`
          return undefined
        })
        .filter(Boolean) as string[],
    ),
  )

  const multicastEntries = Array.from(
    new Set(
      entries
        .map(({ multicastGroupAddress, multicastSource }) => {
          if (multicastGroupAddress && multicastSource) return `${multicastGroupAddress} (source: ${multicastSource})`
          else if (multicastGroupAddress) return multicastGroupAddress
          return undefined
        })
        .filter(Boolean) as string[],
    ),
  )

  return {
    'Connection type': 'Server',
    ...(listenEntries.length ? { 'Listening on': listenEntries.join(', ') } : undefined),
    ...(multicastEntries.length
      ? { [pluralizeWord(multicastEntries.length, 'Multicast group')]: multicastEntries.join(', ') }
      : undefined),
  }
}

function makeApplianceHealthInfo(appliance: Appliance | LimitedAppliance) {
  return 'health' in appliance
    ? { Status: <ServiceOverviewApplianceHealthIndicator applianceId={appliance.id} inline={true} /> }
    : undefined
}

function makeApplianceAlarmsInfo(appliance: Appliance | LimitedAppliance, user: User, routes: Routes) {
  return 'alarms' in appliance && hasAccessToAppliance(appliance, user)
    ? {
        Alarms: appliance.alarms.length ? (
          <Link to={routes.alarms({ applianceId: appliance.id, applianceName: appliance.name })} underline="hover">
            {pluralize(appliance.alarms.length, 'alarm')}
          </Link>
        ) : (
          'No alarms present'
        ),
      }
    : undefined
}

function applianceOverviewLink(applianceName: string) {
  return (
    <ExternalLink
      href={`/grafana/d/appliance-overview/appliance-overview?orgId=1&from=now-1h&to=now&refresh=10s&var-appliance=${applianceName}`}
      underline="always"
      style={{ marginRight: '12px' }}
    >
      Appliance Overview
    </ExternalLink>
  )
}

const InputInfo = ({ selected }: { selected: SelectedGraphItem }) => {
  const { input: _input, parent } = useSelector(
    ({ serviceOverviewReducer }: GlobalState) => ({
      input: serviceOverviewReducer.input,
      parent: serviceOverviewReducer.parent,
    }),
    shallowEqual,
  ) as ServiceOverviewState
  const routes = useRoutes()
  const user = useSelector(({ userReducer }: GlobalState) => userReducer.user, shallowEqual) as User
  if (!_input) {
    return null
  }
  let input = _input
  let isDerivedInput: boolean = false
  if (selected.type === GraphNodeType.parentInput) {
    if (!parent) {
      return null
    }
    input = parent
  } else if (parent) {
    isDerivedInput = true
  }
  const inputId = input.id

  const title =
    selected.type === GraphNodeType.parentInput ? 'Parent input' : isDerivedInput ? 'Derived input' : 'Input'

  const inputApplianceIds = input.appliances?.map((appliance) => appliance.id) || []
  const tsInfosForApplianceByPrio = (tsInfo: TransportStream[]) =>
    tsInfo
      .filter((info) => inputApplianceIds.includes(info.applianceId))
      .sort((a, b) => input.channelIds.indexOf(a.channelId) - input.channelIds.indexOf(b.channelId))
  const tsInfos = tsInfosForApplianceByPrio(input?.tsInfo || [])
  const formatTsInfo = (tsInfos: TransportStream[]) =>
    tsInfos.map((tsInfo) => getFormattedTransportStreamContent(tsInfo)).join(', ') || 'N/A'
  const formats = tsInfos.map((tsInfo) => getFormattedTransportStreamContent(tsInfo)).join(', ') || 'N/A'
  const formatString = isDerivedInput
    ? formatTsInfo(tsInfosForApplianceByPrio(parent?.tsInfo || [])) + ' → ' + formats
    : formats
  const isInputOwner = user.group === input.owner || user.role === Role.super

  const isSuperUser = user.role === Role.super
  const metricsLinks: { text: string; url: string }[] = isSuperUser
    ? [
        {
          text: 'RIST Overview',
          url: `/grafana/d/rist-overview/rist-overview?orgId=1&refresh=10s&from=now-15m&to=now&var-input=${input.id}&var-type=All&var-applianceName=All`,
        },
        {
          text: 'TR 101 290',
          url: `/grafana/d/tr-101-290/tr-101-290?orgId=1&refresh=10s&from=now-15m&to=now&var-input=${input.id}&var-applianceName=All`,
        },
      ]
    : []

  const applianceType = input.appliances?.[0]?.type
  const isVaApplianceInput = applianceType && isVaApplianceType(applianceType)
  if (isSuperUser && !isVaApplianceInput && input.ports?.[0]?.mode === IpPortMode.srt) {
    metricsLinks.push({
      text: 'SRT Overview',
      url: `/grafana/d/srt-overview/srt-overview?orgId=1&refresh=10s&from=now-15m&to=now&var-input=${input.id}&var-type=All&var-applianceName=All`,
    })
  }

  if (isSuperUser && !isVaApplianceInput && input.ports?.[0]?.mode === IpPortMode.rtmp) {
    metricsLinks.push({
      text: 'RTMP Overview',
      url: `/grafana/d/rtmp-overview/rtmp-overview?orgId=1&refresh=10s&from=now-15m&to=now&var-input=${input.id}&var-type=All&var-applianceName=All`,
    })
  }

  const applianceId = input.appliances?.[0]?.id
  if (isSuperUser && !isVaApplianceInput && applianceId && input.deriveFrom?.ingestTransform?.type === 'transcode') {
    metricsLinks.push({
      text: 'Transcoding Overview',
      url: `/grafana/d/transcode-overview/transcode-overview?orgId=1&refresh=10s&from=now-15m&to=now&var-input=${input.id}&var-type=All&var-appliance=${applianceId}`,
    })
  }

  const metricsLink =
    metricsLinks.length > 0
      ? {
          Metrics: (
            <>
              {metricsLinks.map(({ url, text }) => (
                <ExternalLink href={url} underline="always" style={{ marginRight: '12px' }} key={url}>
                  {text}
                </ExternalLink>
              ))}
            </>
          ),
        }
      : {}

  return (
    <InfoSection id="paper-Information" title={title}>
      <Grid item container xs={12} gap={5}>
        <Grid item xs="auto">
          <DataSet
            values={{
              Name: (
                <Link underline="hover" available={isInputOwner} to={routes.inputsUpdate({ id: inputId })}>
                  {input.name}
                </Link>
              ),
              Type: inputType(input).join(', '),
              Status: (
                <InputHealthIndicator
                  status={input.health}
                  inline={true}
                  disabled={input.adminStatus === InputAdminStatus.off}
                />
              ),
              'Channel IDs': input.channelIds.join(', '),
              Format: formatString,
              ...getInputConnectionDetails(input),
              ...metricsLink,
            }}
          />
        </Grid>
      </Grid>
    </InfoSection>
  )
}

const DerivedInputInfo = ({ selected }: { selected: SelectedGraphItem }) => {
  const { input: _input, derivedInputs } = useSelector(
    ({ serviceOverviewReducer }: GlobalState) => ({
      input: serviceOverviewReducer.input,
      derivedInputs: serviceOverviewReducer.derivedInputs,
    }),
    shallowEqual,
  ) as ServiceOverviewState
  const routes = useRoutes()
  const user = useSelector(({ userReducer }: GlobalState) => userReducer.user, shallowEqual) as User
  if (!_input) {
    return null
  }
  const input = derivedInputs!.find((d) => d.id === selected.id)!
  const title = 'Derived input'

  const isInputOwner = user.group === _input.owner || user.role === Role.super

  const isSuperUser = user.role === Role.super
  const metricsLinks: { text: string; url: string }[] = isSuperUser
    ? [
        {
          text: 'RIST Overview',
          url: `/grafana/d/rist-overview/rist-overview?orgId=1&refresh=10s&from=now-15m&to=now&var-input=${input.id}&var-type=All&var-applianceName=All`,
        },
        {
          text: 'TR 101 290',
          url: `/grafana/d/tr-101-290/tr-101-290?orgId=1&refresh=10s&from=now-15m&to=now&var-input=${input.id}&var-applianceName=All`,
        },
      ]
    : []

  const metricsLink =
    metricsLinks.length > 0
      ? {
          Metrics: (
            <>
              {metricsLinks.map(({ url, text }) => (
                <ExternalLink href={url} underline="always" style={{ marginRight: '12px' }} key={url}>
                  {text}
                </ExternalLink>
              ))}
            </>
          ),
        }
      : {}

  const inputApplianceIds = input.appliances?.map((appliance) => appliance.id) || []
  const tsInfosForApplianceByPrio = (tsInfo: TransportStream[]) =>
    tsInfo
      .filter((info) => inputApplianceIds.includes(info.applianceId))
      .sort((a, b) => input.channelIds.indexOf(a.channelId) - input.channelIds.indexOf(b.channelId))
  const formatTsInfo = (tsInfos: TransportStream[]) =>
    tsInfos.map((tsInfo) => getFormattedTransportStreamContent(tsInfo)).join(', ') || 'N/A'
  const formatString =
    formatTsInfo(tsInfosForApplianceByPrio(_input?.tsInfo || [])) +
    ' → ' +
    formatTsInfo(tsInfosForApplianceByPrio(input?.tsInfo || []))

  return (
    <InfoSection id="paper-Information" title={title}>
      <Grid item container xs={12} gap={5}>
        <Grid item xs="auto">
          <DataSet
            values={{
              Name: (
                <Link underline="hover" available={isInputOwner} to={routes.inputsUpdate({ id: input.id })}>
                  {input.name}
                </Link>
              ),
              Type: 'derived',
              Status: (
                <InputHealthIndicator
                  status={input.health}
                  inline={true}
                  disabled={input.adminStatus === InputAdminStatus.off}
                />
              ),
              'Channel IDs': input.channelIds.join(', '),
              Format: formatString,
              ...getInputConnectionDetails(input),
              ...metricsLink,
            }}
          />
        </Grid>
      </Grid>
    </InfoSection>
  )
}

function capitalizePortMode(portMode?: PortMode): undefined | string {
  if (portMode === undefined) {
    return
  }
  return portMode === IpPortMode.zixi ? capitalize(portMode) : portMode.toUpperCase()
}

function getVaInputPipe(logicalPortId: string | undefined, vaObjects?: VaObject[]): VaInputPipe | undefined {
  if (!logicalPortId || !vaObjects) {
    return undefined
  }
  const objectsForPort = vaObjects.filter((v) => v.logicalPort === logicalPortId)
  const edgeSource = objectsForPort.find((v) => v.type === VaObjectInputType.edgeSource)
  if (!edgeSource) {
    return undefined
  }
  const vaInputPipe: VaObject[] = [edgeSource]
  let last: VaObject = edgeSource
  while (last.inputFrom) {
    const prev = objectsForPort.find((o) => o.name === last.inputFrom)
    if (!prev) {
      return undefined
    }
    vaInputPipe.unshift(prev)
    last = prev
  }
  return vaInputPipe as VaInputPipe
}

const InputPortInfo = ({ selected }: { selected: SelectedGraphItem }) => {
  const { input } = useSelector(
    ({ serviceOverviewReducer }: GlobalState) => ({ input: serviceOverviewReducer.input }),
    shallowEqual,
  )
  if (!input) {
    return null
  }
  function isInputPortData(data: any): data is ConnectionData {
    return data?.type === GraphNodeType.inputPort
  }
  const data = isInputPortData(selected.data) ? selected.data : undefined
  if (!data) {
    return null
  }
  const logicalPort = input.ports?.find((port) => port.id === data.logicalPortId)
  const portAppliance = input.appliances?.find((a) => a.id === selected.data.toId)
  const portMetadata: InputPortMetadata = {
    logicalPort,
    portAppliance,
    streamId: data.streamId,
    ristMetrics: input.metrics?.ristMetrics ?? [],
    connectionDetails: getInputConnectionDetails(input, data.logicalPortId) ?? {},
    isDerivedInput: !!input.deriveFrom,
  }

  const unixInfo = unixInputInfo(portMetadata)
  const udpInfo = udpInputInfo(portMetadata)
  const isVa = !!(portAppliance && isVaApplianceType(portAppliance.type))
  if (portMetadata.isDerivedInput) {
    if (isVa) {
      return null
    }
    const mptsInfo = derivedInputInfo(portMetadata, input.deriveFrom)
    return (
      <>
        {mptsInfo && (
          <InfoSection title="MPTS demuxing information">
            <div>
              <DataSet values={mptsInfo} />
            </div>
          </InfoSection>
        )}
        {udpInfo && (
          <InfoSection title={`UDP information`}>
            <div>
              <DataSet values={udpInfo} />
            </div>
          </InfoSection>
        )}
        {unixInfo && (
          <InfoSection title={`Unix socket information`}>
            <div>
              <DataSet values={unixInfo} />
            </div>
          </InfoSection>
        )}
      </>
    )
  }

  const srtInfo = srtInputInfo(portMetadata)
  const zixiInfo = zixiInputInfo(portMetadata)
  const rtmpInfo = rtmpInputInfo(portMetadata)
  const ristInfo = ristInputInfo(portMetadata)
  const rtpInfo = rtpInputInfo(portMetadata)
  const vaInputPipe = isVa ? getVaInputPipe(data.logicalPortId, input.metrics?.vaObjects) : undefined
  const showHandoverTooltip = logicalPort && !isRistServerPortMode(logicalPort.mode)
  return (
    <>
      {rtpInfo && ( // This is not displayed for nimbra VA
        <InfoSection
          title="RTP information"
          tooltip={
            showHandoverTooltip
              ? `The handover from the protocol adapter for ${capitalizePortMode(logicalPort.mode)} uses RTP.`
              : undefined
          }
        >
          <div>
            <DataSet values={rtpInfo} />
          </div>
        </InfoSection>
      )}
      {!isVa &&
        zixiInfo && ( // This is not displayed for nimbra VA
          <InfoSection title="Zixi information">
            <div>
              <DataSet values={zixiInfo} />
            </div>
          </InfoSection>
        )}
      {!isVa &&
        srtInfo && ( // This is not displayed for nimbra VA
          <InfoSection title="SRT information">
            <div>
              <DataSet values={srtInfo} />
            </div>
          </InfoSection>
        )}
      {!isVa &&
        rtmpInfo && ( // This is not displayed for nimbra VA
          <InfoSection title="RTMP information">
            <div>
              <DataSet values={rtmpInfo} />
            </div>
          </InfoSection>
        )}
      {!isVa &&
        ristInfo && ( // This is not displayed for nimbra VA
          <InfoSection title="RIST information">
            <div>
              <DataSet values={ristInfo} />
            </div>
          </InfoSection>
        )}
      {udpInfo && (
        <InfoSection
          title={`UDP information`}
          tooltip={
            showHandoverTooltip
              ? `The handover from the protocol adapter for ${capitalizePortMode(logicalPort.mode)} uses UDP.`
              : undefined
          }
        >
          <div>
            <DataSet values={udpInfo} />
          </div>
        </InfoSection>
      )}
      {unixInfo && (
        <InfoSection
          title={`Unix socket information`}
          tooltip={
            showHandoverTooltip
              ? `The handover from the protocol adapter for ${capitalizePortMode(
                  logicalPort.mode,
                )} uses Unix domain sockets.`
              : undefined
          }
        >
          <div>
            <DataSet values={unixInfo} />
          </div>
        </InfoSection>
      )}
      {vaInputPipe &&
        logicalPort &&
        !isIpcPortMode(logicalPort.mode) &&
        !isVideonPortMode(logicalPort.mode) &&
        !isMatroxPortMode(logicalPort.mode) &&
        !isComprimatoPortMode(logicalPort.mode) && (
          <InfoSection title={`VA ${logicalPort.mode.toUpperCase()} statistics`}>
            <div>{vaInputStatistics(logicalPort.mode, vaInputPipe)}</div>
          </InfoSection>
        )}
    </>
  )
}

const DeriveFromInfo = ({ selected }: { selected: SelectedGraphItem }) => {
  const { input: _input, parent } = useSelector(
    ({ serviceOverviewReducer }: GlobalState) => ({
      input: serviceOverviewReducer.input,
      parent: serviceOverviewReducer.parent,
    }),
    shallowEqual,
  )
  if (!_input) {
    return null
  }
  if (!parent) {
    return null
  }

  function isInputPortData(data: any): data is ConnectionData {
    return data?.type === GraphNodeType.deriveFrom
  }
  const data = isInputPortData(selected.data) ? selected.data : undefined
  if (!data) {
    return null
  }
  const parentRistMetrics = parent.metrics?.ristMetrics
  if (!parentRistMetrics) {
    return null
  }

  const udpOutputMetrics = parentRistMetrics
    .filter((m) => m.type === RistMetricType.udpOutput && m.window === MetricWindow.s10)
    .find((m) => m.streamId === data.streamId)
  if (!udpOutputMetrics) {
    return null
  }
  const udpInfo = udpOutputInfoTable(udpOutputMetrics)

  return (
    <>
      {udpInfo && (
        <InfoSection title={`UDP information`} tooltip={undefined}>
          <div>
            <DataSet values={udpInfo} />
          </div>
        </InfoSection>
      )}
    </>
  )
}

const DeriveFromParentInfo = ({ selected }: { selected: SelectedGraphItem }) => {
  const { parentInput } = useSelector(
    ({ serviceOverviewReducer }: GlobalState) => ({ parentInput: serviceOverviewReducer.input }),
    shallowEqual,
  )
  if (!parentInput) {
    return null
  }
  const data = selected.data as ConnectionData
  // const input = derivedInputs.find(d=>d.id === data.toId)!
  const ristMetrics = parentInput.metrics?.ristMetrics
  if (!ristMetrics) {
    return null
  }
  const udpOutputMetrics = ristMetrics
    .filter((m) => m.type === RistMetricType.udpOutput && m.window === MetricWindow.s10)
    .find((m) => m.streamId === data.streamId)
  if (!udpOutputMetrics) {
    return null
  }
  const udpInfo = udpOutputInfoTable(udpOutputMetrics)

  return (
    <>
      {udpInfo && (
        <InfoSection title={`UDP information`} tooltip={undefined}>
          <div>
            <DataSet values={udpInfo} />
          </div>
        </InfoSection>
      )}
    </>
  )
}

const InputApplianceInfo = ({ selected }: { selected: SelectedGraphItem }) => {
  const { input, appliances } = useSelector(
    ({ serviceOverviewReducer }: GlobalState) => ({
      input: serviceOverviewReducer.input,
      appliances: serviceOverviewReducer.appliances,
    }),
    shallowEqual,
  )
  const routes = useRoutes()
  const user = useSelector(({ userReducer }: GlobalState) => userReducer.user, shallowEqual) as User
  const appliance = appliances.find((a) => a.id == selected.id)
  if (!appliance) {
    return null
  }
  if (!input) {
    throw new Error('No selected input')
  }
  const inputAppliance = input.appliances![0]
  if (!inputAppliance) {
    throw new Error('No input appliance')
  }

  const metricsLinks = user.role === Role.super ? { Metrics: applianceOverviewLink(appliance.name) } : {}

  return (
    <InfoSection id="paper-Information" title="Input appliance">
      <DataSet
        values={{
          Name: (
            <>
              {(hasAccessToAppliance(appliance, user) && (
                <Link underline="hover" to={routes.appliancesUpdate({ id: selected.id })}>
                  {appliance.name}
                </Link>
              )) ||
                appliance.name}
              {'features' in appliance && (
                <ApplianceProxyButton user={user} appliance={appliance} iconButtonProps={{ height: 24 }} />
              )}
            </>
          ),
          Type: appliance.type,
          ...makeApplianceHealthInfo(appliance),
          ...makeApplianceAlarmsInfo(appliance, user, routes),
          ...metricsLinks,
        }}
      />
    </InfoSection>
  )
}

const CoreNodeInfo = ({ selected }: { selected: SelectedGraphItem }) => {
  const { input, appliances } = useSelector(
    ({ serviceOverviewReducer }: GlobalState) => ({
      input: serviceOverviewReducer.input,
      appliances: serviceOverviewReducer.appliances,
    }),
    shallowEqual,
  )
  const routes = useRoutes()
  const user = useSelector(({ userReducer }: GlobalState) => userReducer.user, shallowEqual) as User
  if (!input) {
    return null
  }

  const appliance = appliances.find((a) => a.id == selected.id)
  if (!appliance) {
    return null
  }

  const metricsLinks = user.role === Role.super ? { Metrics: applianceOverviewLink(appliance.name) } : {}

  return (
    <InfoSection id="paper-Information" title="Core node">
      <DataSet
        values={{
          Name:
            (hasAccessToAppliance(appliance, user) && (
              <Link underline="hover" to={routes.appliancesUpdate({ id: selected.id })}>
                {appliance.name}
              </Link>
            )) ||
            appliance.name,
          ...makeApplianceHealthInfo(appliance),
          ...makeApplianceAlarmsInfo(appliance, user, routes),
          ...metricsLinks,
        }}
      />
    </InfoSection>
  )
}

const ThumbNodeInfo = ({ selected }: { selected: SelectedGraphItem }) => {
  const { input, appliances } = useSelector(
    ({ serviceOverviewReducer }: GlobalState) => ({
      input: serviceOverviewReducer.input,
      appliances: serviceOverviewReducer.appliances,
    }),
    shallowEqual,
  )
  const routes = useRoutes()
  const user = useSelector(({ userReducer }: GlobalState) => userReducer.user, shallowEqual) as User
  if (!input) {
    return null
  }

  const appliance = appliances.find((a) => a.id == selected.id)
  if (!appliance) {
    return null
  }

  const metricsLinks = user.role === Role.super ? { Metrics: applianceOverviewLink(appliance.name) } : {}

  return (
    <InfoSection id="paper-Information" title="Thumb node">
      <DataSet
        values={{
          Name:
            (hasAccessToAppliance(appliance, user) && (
              <Link underline="hover" to={routes.appliancesUpdate({ id: selected.id })}>
                {appliance.name}
              </Link>
            )) ||
            appliance.name,
          ...makeApplianceHealthInfo(appliance),
          ...metricsLinks,
        }}
      />
    </InfoSection>
  )
}

const OutputApplianceInfo = ({ selected }: { selected: SelectedGraphItem }) => {
  const { appliances } = useSelector(
    ({ serviceOverviewReducer }: GlobalState) => ({ appliances: serviceOverviewReducer.appliances }),
    shallowEqual,
  )
  const routes = useRoutes()
  const user = useSelector(({ userReducer }: GlobalState) => userReducer.user, shallowEqual) as User

  const appliance = appliances.find(({ id }) => id === selected.id)
  if (!appliance) return null

  const metricsLinks = user.role === Role.super ? { Metrics: applianceOverviewLink(appliance.name) } : {}

  return (
    <InfoSection id="paper-Information" title="Output appliance">
      <DataSet
        values={{
          Name: (
            <>
              {(hasAccessToAppliance(appliance, user) && (
                <Link underline="hover" to={routes.appliancesUpdate({ id: selected.id })}>
                  {appliance.name}
                </Link>
              )) ||
                appliance.name}
              {'features' in appliance && (
                <ApplianceProxyButton user={user} appliance={appliance} iconButtonProps={{ height: 24 }} />
              )}
            </>
          ),
          Type: appliance.type,
          ...makeApplianceHealthInfo(appliance),
          ...makeApplianceAlarmsInfo(appliance, user, routes),
          ...metricsLinks,
        }}
      />
    </InfoSection>
  )
}

function getApplianceRistMetrics(input: Input, outputs: Output[]) {
  const ristMetrics: StreamMetrics[] = [
    input.metrics?.ristMetrics || [],
    ...outputs.map((o) => o.metrics?.ristMetrics || []),
  ].flat()
  return ristMetrics
}

export function getConnectionMetrics(input: Input, outputs: Output[], from: string, to: string) {
  const ristMetrics = getApplianceRistMetrics(input, outputs)
  if (ristMetrics.length === 0) return { sendMetrics: undefined, receiveMetrics: undefined }

  const belongsToConnection = (m: StreamMetrics) => {
    return (
      (m.clientApplianceId === from && m.serverApplianceId === to) ||
      (m.serverApplianceId === from && m.clientApplianceId === to)
    )
  }

  const candidateSendMetrics = ristMetrics.filter(
    (m) => belongsToConnection(m) && isRistOutputMetrics(m) && m.applianceId === from && m.window === MetricWindow.s10,
  ) as RistOutputMetrics[]
  const activeChannelId = getActiveChannelId(from, input, outputs)

  // There should be 1 ristOutput per channel.
  // Prefer the one belonging to the active channel, but fallback to the one with the highest sendBitrate if there is no ristOutput of the active channel
  // (e.g. in a multi-appliance setup, if the secondary ingress appliance has a ristInput receiving the primary channel
  // (i.e an output assigned this input is located on the secondary ingress appliance), the "getActiveChannelId()" above
  // will return the primary channel id.
  const sendMetrics =
    candidateSendMetrics.find((m) => m.channelId === activeChannelId) ??
    candidateSendMetrics.reduce((prev, curr) => {
      return prev && prev.sendBitrate > curr.sendBitrate ? prev : curr
    }, undefined as RistOutputMetrics | undefined)

  // There should be 1 ristInput per channel.
  // Select the one receiving from the ristOutput (i.e. same channel).
  const receiveMetrics = ristMetrics.find(
    (m) =>
      belongsToConnection(m) &&
      isRistInputMetrics(m) &&
      m.applianceId === to &&
      m.window === MetricWindow.s10 &&
      m.channelId === sendMetrics?.channelId,
  ) as RistInputMetrics | undefined
  return { sendMetrics, receiveMetrics }
}

const ConnectionInfo = ({ selected }: { selected: SelectedGraphItem }) => {
  const { input, tunnels, appliances } = useSelector(
    ({ serviceOverviewReducer }: GlobalState) => ({
      input: serviceOverviewReducer.input,
      tunnels: serviceOverviewReducer.tunnels,
      appliances: serviceOverviewReducer.appliances,
    }),
    shallowEqual,
  )
  const user = useSelector(({ userReducer }: GlobalState) => userReducer.user, shallowEqual) as User

  const routes = useRoutes()

  const outputs = useSelector(({ outputsReducer }: GlobalState) => outputsReducer.outputs, shallowEqual)

  if (!input) {
    return null
  }
  const [from, to] = selected.id.split('>')
  const fromAppliance = appliances.find((a) => a.id == from)
  const toAppliance = appliances.find((a) => a.id == to)
  if (!fromAppliance || !toAppliance) {
    return null
  }

  const { sendMetrics, receiveMetrics } = getConnectionMetrics(input, outputs, from, to)

  const sendMetric = (getter: (s: RistOutputMetrics) => any) => {
    if (!sendMetrics) {
      return 'N/A'
    }
    const val = getter(sendMetrics)
    if (typeof val === 'undefined' || Number.isNaN(val)) {
      return 'N/A'
    }
    return val
  }
  const receiveMetric = (getter: (s: RistInputMetrics) => any) => {
    if (!receiveMetrics) {
      return 'N/A'
    }
    const val = getter(receiveMetrics)
    if (typeof val === 'undefined' || Number.isNaN(val)) {
      return 'N/A'
    }
    return val
  }

  let senderTunnelInfo: ClientConnectionDetails | ServerConnectionDetails | Record<string, never> = {}
  let receiverTunnelInfo: ClientConnectionDetails | ServerConnectionDetails | Record<string, never> = {}
  const networkInfo: { [key: string]: string | React.ReactNode } = {}

  const tunnel = tunnels.find(
    (t) =>
      (t.client === fromAppliance.id && t.server === toAppliance.id) ||
      (t.client === toAppliance.id && t.server === fromAppliance.id),
  )
  if (tunnel) {
    const tunnelClientInfo: ClientConnectionDetails & { 'Tunnel id'?: any } = makeClientConnectionDetails([
      {
        fromAddress: tunnel.clientLocalIp,
        fromPort: tunnel.clientLocalPort,
        toAddress: tunnel.clientRemoteIp,
        toPort: tunnel.clientRemotePort,
      },
    ])

    const isSuperUser = user.role == Role.super
    const tunnelLinkComponent = isSuperUser ? (
      <Link to={routes.tunnel({ tunnelId: tunnel.id })} underline="hover">
        {tunnel.id}
      </Link>
    ) : (
      tunnel.id
    )

    const tunnelServerInfo: ServerConnectionDetails & { 'Tunnel id'?: any } = makeServerConnectionDetails([
      {
        listenAddress: tunnel.serverLocalIp,
        listenPort: tunnel.serverLocalPort,
      },
    ])

    tunnelClientInfo['Tunnel id'] = tunnelLinkComponent
    tunnelServerInfo['Tunnel id'] = tunnelLinkComponent

    // Input: Sender (tunnel client on external appliance) pushes to receiver (tunnel server on core appliance)
    // Output: Receiver (tunnel client on external appliance) pulls from sender (tunnel server on core appliance)
    senderTunnelInfo = fromAppliance.id === tunnel.client ? tunnelClientInfo : tunnelServerInfo
    receiverTunnelInfo = toAppliance.id === tunnel.client ? tunnelClientInfo : tunnelServerInfo
    if (tunnel.network)
      networkInfo['Network'] = (
        <Link to={routes.networkUpdate({ id: tunnel.network.id })} underline="hover">
          {tunnel.network.name}
        </Link>
      )
    else {
      networkInfo['Network'] = TunnelLabels[tunnel.type]
    }
  }

  const inputMultipathState = receiveMetrics && receiveMetrics.multipathState
  const inputMultiPathStateData = inputMultipathState
    ? {
        'Multipath state': inputMultipathState,
      }
    : {}
  const outputMultipathState = sendMetrics && sendMetrics.multipathState
  const outputMultiPathStateData = outputMultipathState
    ? {
        'Multipath state': outputMultipathState,
      }
    : {}
  return (
    <InfoSection id="paper-Information" title={'RIST stream'}>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <h2>
            Sent from <span style={{ fontWeight: 'normal' }}>{fromAppliance.name}</span>
          </h2>
          <DataSet
            values={{
              ...senderTunnelInfo,
              ...networkInfo,
              ...outputMultiPathStateData,
              Bitrate: sendMetric((s) => formatBitrate(s.sendBitrate)),
              'Packet rate': sendMetric((s) => Math.round(s.sendPacketrate)),
              'Packet send errors': sendMetric((s) => s.packetSendErrors),
              'Retransmission bitrate': formatBitrate(sendMetric((s) => s.retransmitSendBitrate)),
              'Retransmission packet rate': sendMetric((s) => Math.round(s.retransmitSendPacketrate)),
              'RTP packets sent': sendMetric((s) => s.rtpPacketsSent),
              Roundtrip: sendMetric((s) => applyUnit(s.roundtripMs, 'ms')),
              'Internal Stream ID': sendMetric((s) => s.streamId),
              'Internal Channel ID': sendMetric((s) => s.channelId),
            }}
          />
        </div>
        <div style={{ flex: 1 }}>
          <h2>
            Received by <span style={{ fontWeight: 'normal' }}>{toAppliance.name}</span>
          </h2>
          <DataSet
            values={{
              ...receiverTunnelInfo,
              ...networkInfo,
              ...inputMultiPathStateData,
              Bitrate: receiveMetric((s) => formatBitrate(s.receiveBitrate)),
              'Packet rate': receiveMetric((s) => Math.round(s.receivePacketrate)),
              'Packet send errors': receiveMetric((s) => s.packetSendErrors),
              'Packets discarded': receiveMetric((s) => s.packetsDiscarded),
              'Retransmission bitrate': receiveMetric((s) => formatBitrate(s.retransmitReceiveBitrate)),
              'Retransmission packet rate': receiveMetric((s) => Math.round(s.retransmitReceivePacketrate)),
              'Interarrival jitter': receiveMetric((s) => applyUnit(s.interarrivalJitter!, 'µs')),
              'RTP packets received': receiveMetric((s) => s.rtpPacketsReceived),
              Roundtrip: receiveMetric((s) => applyUnit(s.roundtripMs, 'ms')),
              'Propagation delay': receiveMetric((s) => applyUnit(s.propagationDelayMs, 'ms')),
              'Packet loss': receiveMetric((s) =>
                s.packetsReceived > 0
                  ? ((100 * s.packetsLost) / (s.packetsReceived + s.packetsLost)).toFixed(1) + '%'
                  : undefined,
              ),
              'Longest burst loss': receiveMetric((s) => s.longestBurstLoss),
              'Internal Stream ID': receiveMetric((s) => s.streamId),
              'Internal Channel ID': receiveMetric((s) => s.channelId),
            }}
          />
        </div>
      </div>
    </InfoSection>
  )
}

const OutputInfo = ({ selected }: { selected: SelectedGraphItem }) => {
  const routes = useRoutes()
  const outputId = selected.id
  const outputs = useSelector(({ outputsReducer }: GlobalState) => outputsReducer.outputs, shallowEqual)
  const output = outputs.find(({ id }) => id === outputId)
  if (!output) {
    return null
  }

  const ristServerEgressMetricsPacketsLost2min = output.metrics?.ristMetrics
    .filter(
      (m) =>
        m.outputId == output.id &&
        m.isEgress === true &&
        m.window === MetricWindow.m1 &&
        m.sampledAt &&
        ageSeconds(m.sampledAt) <= 120,
    )
    .filter(isOutputMetricsWithPacketsLost)
    .map((m) => m.packetsLost)

  const egressPacketsLost2min = ristServerEgressMetricsPacketsLost2min?.length
    ? ristServerEgressMetricsPacketsLost2min.reduce((sum, packetsLost) => sum + packetsLost, 0)
    : null

  const packetsLost =
    typeof egressPacketsLost2min === 'number'
      ? {
          'Packets Lost (last 2 minutes)': packets(egressPacketsLost2min),
        }
      : {}
  const ports = output.ports

  return (
    <>
      <InfoSection id="paper-Information" title="Output">
        <div>
          <DataSet
            values={{
              Name: (
                <Link underline="hover" to={routes.outputsUpdate({ id: outputId })}>
                  {output.name}
                </Link>
              ),
              Type: distinct(ports.map((port) => outputType(port))).join(', '),
              Status: (
                <OutputHealthIndicator
                  status={output.health}
                  inline={true}
                  disabled={output.adminStatus === OutputAdminStatus.off}
                />
              ),
              ...packetsLost,
              ...getOutputConnectionDetails(output),
            }}
          />
        </div>
      </InfoSection>
    </>
  )
}

function getVaOutputPipe(logicalPortId: string | undefined, vaObjects?: VaObject[]): VaOutputPipe | undefined {
  if (!logicalPortId || !vaObjects) {
    return undefined
  }
  const objectsForPort = vaObjects.filter((v) => v.logicalPort === logicalPortId)

  const finalDestination = objectsForPort.find((o) => o.type !== 'edge-sink' && o.type !== 'decoder')
  if (!finalDestination) {
    return undefined
  }

  const outputChain: VaObject[] = [finalDestination]
  let inputFrom = finalDestination.inputFrom
  while (inputFrom) {
    const nextVaObject = objectsForPort.find((o) => o.name == inputFrom)
    if (nextVaObject) {
      outputChain.unshift(nextVaObject)
      inputFrom = nextVaObject.inputFrom
    } else {
      break
    }
  }

  return outputChain as VaOutputPipe
}

const OutputPortInfo = ({ selected }: { selected: SelectedGraphItem }) => {
  const outputId = selected?.data?.toId
  const outputs = useSelector(({ outputsReducer }: GlobalState) => outputsReducer.outputs, shallowEqual)
  const output = outputs.find(({ id }) => id === outputId)
  if (!output) {
    return null
  }
  function isOutputPortData(data: any): data is ConnectionData {
    return data?.type === GraphNodeType.outputPort
  }
  const data = isOutputPortData(selected.data) ? selected.data : undefined
  if (!data) {
    return null
  }
  const logicalPort = output.ports?.find((port) => port.id === selected?.data?.logicalPortId)
  if (!logicalPort) {
    return null
  }

  const portAppliance = output.appliances?.find((a) => a.id === selected.data.fromId)
  const portMetadata: OutputPortMetadata = {
    logicalPort,
    portAppliance,
    streamId: data.streamId,
    ristMetrics: output.metrics?.ristMetrics ?? [],
    connectionDetails: getOutputConnectionDetails(output, data.logicalPortId) ?? {},
  }

  const srtInfo = srtOutputInfo(portMetadata)
  const zixiInfo = zixiOutputInfo(portMetadata)
  const rtmpInfo = rtmpOutputInfo(portMetadata)
  const ristInfo = ristOutputInfo(portMetadata)
  const udpInfo = udpOutputInfo(portMetadata, output)
  const unixInfo = unixOutputInfo(portMetadata)
  const rtpInfo = rtpOutputInfo(portMetadata)

  const isVa = !!(portAppliance && isVaApplianceType(portAppliance.type))
  const vaOutputPipe = isVa ? getVaOutputPipe(data.logicalPortId, output.metrics?.vaObjects) : undefined
  const showHandoverTooltip = !isRistServerPortMode(logicalPort.mode) || isVa

  const isActuallyRtpOutput = logicalPort.mode === IpPortMode.rtp || isUdpOutputPortWithRtpFormat(logicalPort)
  return (
    <>
      {udpInfo && !isActuallyRtpOutput && (
        <InfoSection
          title="UDP information"
          tooltip={
            showHandoverTooltip
              ? `The handover to the protocol adapter for ${capitalizePortMode(logicalPort.mode)} uses UDP.`
              : undefined
          }
        >
          <div>
            <DataSet values={udpInfo} />
          </div>
        </InfoSection>
      )}
      {unixInfo && (
        <InfoSection
          title="Unix socket information"
          tooltip={
            showHandoverTooltip
              ? `The handover to the protocol adapter for ${capitalizePortMode(
                  logicalPort.mode,
                )} uses Unix domain sockets.`
              : undefined
          }
        >
          <div>
            <DataSet values={unixInfo} />
          </div>
        </InfoSection>
      )}
      {rtpInfo && (
        <InfoSection
          title="RTP information"
          tooltip={
            showHandoverTooltip
              ? `The handover to the protocol adapter for ${capitalizePortMode(logicalPort.mode)} uses RTP.`
              : undefined
          }
        >
          <div>
            <DataSet values={rtpInfo} />
          </div>
        </InfoSection>
      )}
      {!isVa &&
        zixiInfo && ( // This is not displayed for nimbra VA
          <InfoSection title="Zixi information">
            <div>
              <DataSet values={zixiInfo} />
            </div>
          </InfoSection>
        )}
      {!isVa &&
        srtInfo && ( // This is not displayed for nimbra VA
          <InfoSection title="SRT information">
            <div>
              <DataSet values={srtInfo} />
            </div>
          </InfoSection>
        )}
      {!isVa &&
        rtmpInfo && ( // This is not displayed for nimbra VA
          <InfoSection title="RTMP information">
            <div>
              <DataSet values={rtmpInfo} />
            </div>
          </InfoSection>
        )}
      {!isVa &&
        ristInfo && ( // This is not displayed for nimbra VA
          <InfoSection title="RIST information">
            <div>
              <DataSet values={ristInfo} />
            </div>
          </InfoSection>
        )}
      {vaOutputPipe && (
        <InfoSection title={`VA ${output.ports[0].mode.toUpperCase()} statistics`}>
          <div>
            <VaOutputStatistics port={portMetadata.logicalPort} vaOutputPipe={vaOutputPipe} />
          </div>
        </InfoSection>
      )}
    </>
  )
}

interface Props {
  selected: SelectedGraphItem
}

const Info = ({ selected }: Props): JSX.Element | null => {
  return (
    <>
      {selected.type === GraphNodeType.parentInput && <InputInfo selected={selected} />}
      {selected.type === GraphNodeType.input && <InputInfo selected={selected} />}
      {selected.type === GraphNodeType.derivedInput && <DerivedInputInfo selected={selected} />}
      {selected.type === GraphNodeType.inputEdgeAppliance && <InputApplianceInfo selected={selected} />}
      {selected.type === GraphNodeType.thumbAppliance && <ThumbNodeInfo selected={selected} />}
      {selected.type === GraphNodeType.inputRegionCore && <CoreNodeInfo selected={selected} />}
      {selected.type === GraphNodeType.outputRegionCore && <CoreNodeInfo selected={selected} />}
      {selected.type === GraphNodeType.inputRegionOutputEdgeAppliance && <OutputApplianceInfo selected={selected} />}
      {selected.type === GraphNodeType.outputRegionOutputEdgeAppliance && <OutputApplianceInfo selected={selected} />}
      {selected.type === GraphNodeType.output && <OutputInfo selected={selected} />}
      {selected.type === GraphNodeType.connection && <ConnectionInfo selected={selected} />}
      {selected.type === GraphNodeType.deriveFrom && <DeriveFromInfo selected={selected} />}
      {selected.type === GraphNodeType.deriveFromParent && <DeriveFromParentInfo selected={selected} />}
      {selected.type === GraphNodeType.inputPort && <InputPortInfo selected={selected} />}
      {selected.type === GraphNodeType.outputPort && <OutputPortInfo selected={selected} />}
      {isGraphNodeApplianceType(selected.type) && <Tr101290Page selected={selected} />}
      {isGraphNodeApplianceType(selected.type) && <TsInfo selected={selected} />}
    </>
  )
}

export default Info
