import React, { useEffect } from 'react'
import { useForm, FormProvider, DefaultValues, UseFormReturn, FieldErrors } from 'react-hook-form'

/**
 * AllowUncontrolled is a type that allows any key-value pair.
 * It is used as a type instead of `any` to make it clear that uncontrolled fields exists.
 * It also prevents syntax errors when accessing an uncontrolled field, such as `getValues('some.uncotrolled.field')`.
 *
 * It is often used combined with the actual type of the FormProps, such as `FormProps<T & AllowUncontrolled>`.
 * since it allows automatic type inference for the actual type of the form, but also allows any key-value pair.
 * */
export type AllowUncontrolled = { [key: string]: any }
export type FormDataType = Record<string, any>

export type FormProps<T extends Record<string, any>> = Omit<UseFormReturn<T>, 'handleSubmit'> & {
  /**
   * The initial values of the form.
   * */
  defaultValues: T
  /**
   * A function that triggers the form validation and calls the onSubmit function if the form is valid.
   * */
  submitForm: () => Promise<void> | void
}

export type RhfProps<T extends FormDataType> = {
  /**
   * The initial values of the form.
   */
  defaultValues?: T
  /**
   * If true, the form will be reinitialized with the new initialValues
   * whenever the initialValues prop changes.
   * @default false
   * */
  enableReinitialize?: boolean
  /**
   * The function to be called when the form is submitted.
   * */
  onSubmit: (data: T, formContext: UseFormReturn<T>) => void | Promise<any>
  /**
   * The component to be rendered.
   */
  component?: React.ElementType
  /**
   * The children of the component.
   * */
  children?: React.ReactNode | ((props: FormProps<T>) => React.ReactNode)
}

export type OnSubmitProps = { onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void> }
export const OnSubmit = React.createContext<OnSubmitProps | undefined>(undefined)

/**
 * A wrapper around react-hook-form that provides a submit function and a context for the submit function.
 * @param defaultValues The initial values of the form.
 * @param enableReinitialize If true, the form will be reinitialized with the new initialValues whenever the initialValues prop changes.
 * @param onSubmit The function to be called when the form is submitted.
 * @param component The component to be rendered.
 * @param children The children of the component.
 * @returns The form component.
 * */
const RHF = <T extends FormDataType>({
  defaultValues,
  enableReinitialize,
  onSubmit,
  component: Component,
  children,
}: RhfProps<T>) => {
  const methods = useForm<T>({
    mode: 'all',
    defaultValues: defaultValues as DefaultValues<T>,
  })

  useEffect(() => {
    if (enableReinitialize) {
      methods.reset(defaultValues)
    }
  }, [defaultValues, enableReinitialize])

  const onError = (errors: FieldErrors) => {
    const firstErrorField = Object.keys(errors)[0]
    if (firstErrorField) {
      try {
        methods.setFocus(firstErrorField as any, { shouldSelect: true })
      } catch (e) {
        methods.setFocus(firstErrorField as any, { shouldSelect: false })
      }
    }
  }

  const submitContextProps: OnSubmitProps = {
    onSubmit: (e) => methods.handleSubmit(async (data) => onSubmit(data, methods), onError)(e),
  }

  const props = {
    ...methods,
    defaultValues: defaultValues || {},
    submitForm: () => {
      methods.trigger().then((valid) => {
        if (valid) {
          submitContextProps.onSubmit()
        }
      })
    },
  } as FormProps<T>

  return (
    <FormProvider {...methods}>
      <OnSubmit.Provider value={submitContextProps}>
        {Component && <Component {...props} />}
        {typeof children === 'function' ? (children as (props: FormProps<T>) => React.ReactNode)(props) : children}
      </OnSubmit.Provider>
    </FormProvider>
  )
}

export default RHF
