import { useState } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'

import { Output, OutputRecipientList, OutputRecipientListUpdate } from 'common/api/v1/types'
import { Api } from '../../../store'
import PaginatedList from '../../common/SelfStatePaginatedList'
import { Table } from '../../common/Table'
import { AutoUpdatingOutputHealthIndicator } from '../../common/Indicator'
import { useUser } from '../../../utils'
import { OutputsRequestParams } from '../../../api/nm-types'
import { useFormContext } from 'react-hook-form'

const styles = {
  button: {
    color: (theme: Theme) => theme.palette.error.main,
    borderColor: (theme: Theme) => theme.palette.error.main,
    '&:hover': {
      borderColor: (theme: Theme) => theme.palette.error.dark,
    },
  },
}

const { outputListsApi, outputApi } = Api

const AddRemoveButton = ({ id }: { id: Output['id'] }) => {
  const { setValue, watch } = useFormContext<OutputRecipientList & Pick<OutputRecipientListUpdate, 'addOutputs'>>()
  const currentAddOutputs = watch('addOutputs')
  const onAdd = (id: Output['id']) => setValue('addOutputs', [...currentAddOutputs, id])
  const onCancel = (id: Output['id']) => {
    setValue(
      'addOutputs',
      currentAddOutputs.filter((outputId) => outputId !== id),
    )
  }

  return currentAddOutputs.includes(id) ? (
    <Button sx={styles.button} onClick={() => onCancel(id)} variant="outlined" size="small">
      Cancel
    </Button>
  ) : (
    <Button onClick={() => onAdd(id)} variant="outlined" size="small">
      Add
    </Button>
  )
}

const AvailableOutputs = () => {
  const [hideFilter, setHideFilter] = useState(true)
  const { getValues } = useFormContext<OutputRecipientList & Pick<OutputRecipientListUpdate, 'addOutputs'>>()
  const values = getValues()
  const user = useUser()
  const listGroup = values.group ?? user?.group

  return (
    <Grid item xs={12}>
      <PaginatedList<OutputsRequestParams, Output>
        api={
          values.id
            ? function (params) {
                return outputListsApi.getOutputs(values.id, false, { group: listGroup, ...params })
              }
            : outputApi.getOutputs.bind(outputApi)
        }
        emptyMessage="no available outputs"
        hideSearch={hideFilter}
        notFoundMessage="no matching outputs"
        onListChange={({ filter, total = 0 }) => setHideFilter(!filter && total < 10)}
        searchPlaceholder="Find specific output…"
        List={({ list }) => (
          <Table
            data={values.group ? list.filter((output: Output) => output.group === values.group) : list}
            isSmall
            hasBorders
            noHeader
            config={[
              {
                getValue: ({ name }) => <Typography>{name}</Typography>,
              },
              {
                getValue: (output) => <AutoUpdatingOutputHealthIndicator initialOutput={output} />,
              },
              {
                getValue: ({ id }) => <AddRemoveButton id={id} />,
                props: { align: 'right', padding: 'normal' },
              },
            ]}
          />
        )}
      />
    </Grid>
  )
}

export default AvailableOutputs
