import { useState } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Tooltip from '../../common/Tooltip'
import Typography from '@mui/material/Typography'
import HighlightOff from '@mui/icons-material/HighlightOff'
import { Theme } from '@mui/material/styles'

import { Group, GroupRecipientList, GroupRecipientListUpdate } from 'common/api/v1/types'
import { Api } from '../../../store'
import PaginatedList from '../../common/SelfStatePaginatedList'
import { Table } from '../../common/Table'
import { GroupsRequestParams } from '../../../api/nm-types'
import { useFormContext } from 'react-hook-form'

const styles = {
  button: {
    color: (theme: Theme) => theme.palette.error.main,
    borderColor: (theme: Theme) => theme.palette.error.main,
    '&:hover': {
      borderColor: (theme: Theme) => theme.palette.error.dark,
    },
  },
}

const groupListsApi = Api.groupListsApi

const RemoveButton = ({ id }: { id: Group['id'] }) => {
  const { setValue, watch } = useFormContext<GroupRecipientList & Pick<GroupRecipientListUpdate, 'removeGroups'>>()
  const currentRemoveGroups = watch('removeGroups')
  const onRemove = (id: Group['id']) => setValue('removeGroups', [...currentRemoveGroups, id])
  const onCancel = (id: Group['id']) => {
    setValue(
      'removeGroups',
      currentRemoveGroups.filter((groupId) => groupId !== id),
    )
  }

  return currentRemoveGroups.includes(id) ? (
    <Button sx={styles.button} onClick={() => onCancel(id)} variant="outlined" size="small">
      Cancel
    </Button>
  ) : (
    <Tooltip title="Remove from list" placement="top">
      <IconButton edge="start" aria-label="remove" onClick={() => onRemove(id)}>
        <HighlightOff />
      </IconButton>
    </Tooltip>
  )
}

const Groups = () => {
  const [hideFilter, setHideFilter] = useState(true)
  const { getValues } = useFormContext<GroupRecipientList & Pick<GroupRecipientListUpdate, 'removeGroups'>>()
  const values = getValues()

  return (
    <Grid item xs={12}>
      <PaginatedList<GroupsRequestParams, Group>
        api={function (params) {
          return groupListsApi.getGroups(values.id, true, params)
        }}
        emptyMessage="no groups"
        hideSearch={hideFilter}
        notFoundMessage="no matching groups"
        onListChange={({ filter, total = 0 }) => {
          setHideFilter(!filter && total < 10)
        }}
        searchPlaceholder="Find specific group…"
        List={({ list }) => (
          <Table
            id="selectgroups-table"
            data={list}
            isSmall
            hasBorders
            noHeader
            config={[
              {
                getValue: ({ name }) => <Typography>{name}</Typography>,
              },
              {
                getValue: ({ id }) => <RemoveButton id={id} />,
                props: { align: 'right', padding: 'normal' },
              },
            ]}
          />
        )}
      />
    </Grid>
  )
}

export default Groups
