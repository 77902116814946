import { useState } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Tooltip from '../../common/Tooltip'
import Typography from '@mui/material/Typography'
import HighlightOff from '@mui/icons-material/HighlightOff'
import { Theme } from '@mui/material/styles'

import { Output, OutputRecipientList, OutputRecipientListUpdate } from 'common/api/v1/types'
import { Api } from '../../../store'
import PaginatedList from '../../common/SelfStatePaginatedList'
import { Table } from '../../common/Table'
import { AutoUpdatingOutputHealthIndicator } from '../../common/Indicator'
import { OutputsRequestParams } from '../../../api/nm-types'
import { useFormContext } from 'react-hook-form'

const styles = {
  button: {
    color: (theme: Theme) => theme.palette.error.main,
    borderColor: (theme: Theme) => theme.palette.error.main,
    '&:hover': {
      borderColor: (theme: Theme) => theme.palette.error.dark,
    },
  },
}

const outputListsApi = Api.outputListsApi

const RemoveButton = ({ id }: { id: Output['id'] }) => {
  const { setValue, watch } = useFormContext<OutputRecipientList & Pick<OutputRecipientListUpdate, 'removeOutputs'>>()
  const currentRemoveOutputs = watch('removeOutputs')
  const onRemove = (id: Output['id']) => setValue('removeOutputs', [...currentRemoveOutputs, id])
  const onCancel = (id: Output['id']) => {
    setValue(
      'removeOutputs',
      currentRemoveOutputs.filter((outputId) => outputId !== id),
    )
  }

  return currentRemoveOutputs.includes(id) ? (
    <Button sx={styles.button} onClick={() => onCancel(id)} variant="outlined" size="small">
      Cancel
    </Button>
  ) : (
    <Tooltip title="Remove from list" placement="top">
      <IconButton edge="start" aria-label="remove" onClick={() => onRemove(id)}>
        <HighlightOff />
      </IconButton>
    </Tooltip>
  )
}

const Outputs = () => {
  const [hideFilter, setHideFilter] = useState(true)
  const { getValues } = useFormContext<OutputRecipientList & Pick<OutputRecipientListUpdate, 'removeOutputs'>>()
  const values = getValues()

  return (
    <Grid item xs={12}>
      <PaginatedList<OutputsRequestParams, Output>
        api={function (params) {
          return outputListsApi.getOutputs(values.id, true, params)
        }}
        emptyMessage="no outputs"
        hideSearch={hideFilter}
        notFoundMessage="no matching outputs"
        onListChange={({ filter, total = 0 }) => {
          setHideFilter(!filter && total < 10)
        }}
        searchPlaceholder="Find specific output…"
        List={({ list }) => (
          <Table
            data={list}
            isSmall
            hasBorders
            noHeader
            config={[
              {
                getValue: ({ name }) => <Typography>{name}</Typography>,
              },
              {
                getValue: (output) => <AutoUpdatingOutputHealthIndicator initialOutput={output} />,
              },
              {
                getValue: ({ id }) => <RemoveButton id={id} />,
                props: { align: 'right', padding: 'normal' },
              },
            ]}
          />
        )}
      />
    </Grid>
  )
}

export default Outputs
